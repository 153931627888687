import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { JbdUiIconButtonComponent } from '../../../../elements/button/icon/icon.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'jbd-ui-layout-toolbar-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    JbdUiIconButtonComponent,
    FlexLayoutModule,
    MatToolbarModule,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLayoutToolbarMainComponent {
  @Output() public toggleSidenav = new EventEmitter<undefined>();

  public emitSidenavToggle() {
    this.toggleSidenav.emit();
  }
}
