import { JbdNavigationAdminViewComponent } from './page/admin-view/admin-view.component';
import { JbdNavigationPageComponent } from './page/page.component';
import { JbdNavigationSimpleViewComponent } from './page/simple-view/simple-view.component';
import { JbdSharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';

const IMPORTED_MODULES = [JbdSharedModule];
const NAVIGATION_COMPONENTS = [
  JbdNavigationAdminViewComponent,
  JbdNavigationPageComponent,
  JbdNavigationSimpleViewComponent,
];

@NgModule({
  declarations: [...NAVIGATION_COMPONENTS],
  imports: [...IMPORTED_MODULES],
})
export class JbdNavigationModule {}
