export const PARTNER_API_PATHS = {
  PARTNER: {
    ME: 'partners/:partnerId',
    ATTRIBUTES: 'partners/:partnerId/attributes',
  },
  LOCATION: {
    TYPES: 'locationTypes',
    LIST: 'partners/:partnerId/locations',
    DETAILS: 'partners/:partnerId/locations/:locationId',
    ADDRESSES: 'partners/:partnerId/locations/:locationId/addresses',
    ADDRESS: 'partners/:partnerId/locations/:locationId/addresses/:addressId',
    FILES: 'partners/:partnerId/locations/:locationId/files',
    FILE_DETAILS: 'partners/:partnerId/locations/:locationId/files/:fileId',
    SUGGESTED_IMAGES: 'partners/:partnerId/locations/:locationId/images',
    BANK_DETAILS: 'partners/:partnerId/locations/:locationId/bank',
    CONTRACT: 'partners/:partnerId/locations/:locationId/contract',
    ATTRIBUTES: 'partners/:partnerId/locations/:locationId/attributes',
  },
  USER_MANAGEMENT: {
    LIST: 'partners/:partnerId/users',
    DETAILS: 'partners/:partnerId/users/:userId',
  },
  PAYOUTS: {
    LIST: 'partners/:partnerId/locations/:locationId/credits',
    PAYOUT_DOWNLOAD:
      'partners/:partnerId/locations/:locationId/credits/:creditId/download',
  },
  RECEIPT: {
    CONVERT: 'public-partners/convertBookings/:convertBookingId',
    DETAILS:
      'public-partners/:partnerId/locations/:locationId/bookings/:bookingId',
    FILES:
      'public-partners/:partnerId/locations/:locationId/bookings/:bookingId/files',
  },
  BOOKING: {
    LIST: 'partners/:partnerId/locations/:locationId/bookings',
    TASK: 'partners/:partnerId/locations/:locationId/bookings/needsAction',
    DETAILS: 'partners/:partnerId/locations/:locationId/bookings/:bookingId',
    INFO_CARD:
      'partners/:partnerId/locations/:locationId/bookings/:bookingId/infocard',
  },
  VACATION: {
    LIST: 'partners/:partnerId/locations/:locationId/vacations',
    DETAILS: 'partners/:partnerId/locations/:locationId/vacations/:vacationId',
  },
};
