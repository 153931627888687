<ng-container
  *ngTemplateOutlet="isAdmin ? adminView : simpleView"
></ng-container>

<ng-template #simpleView>
  <jbd-app-navigation-simple-view
    [locations]="locations"
  ></jbd-app-navigation-simple-view>
</ng-template>

<ng-template #adminView>
  <jbd-app-navigation-admin-view
    [locations]="locations"
  ></jbd-app-navigation-admin-view>
</ng-template>
