<ng-container *transloco="let t; read: 'UI.LAYOUT.SIDENAV.USER_ACTIONS'">
  <div class="sidenav-user-actions p-16">
    <jbd-ui-dropdown
      [customTriggerTemplate]="triggerTemplate"
      [menuClassList]="['sidenav-user-actions-dropdown']"
      [items]="[
        { label: t('PROFILE'), emitValue: {type: 'profile'} },
        { label: t('LOGOUT'), emitValue: {type: 'logout'} },
      ]"
      (actionClicked)="$event.type === 'profile' ? router.navigate(['/benutzer/daten']) : logout()"
      class="block"
    ></jbd-ui-dropdown>

    <ng-template
      #triggerTemplate
      let-menu="menu"
    >
      <button
        [matMenuTriggerFor]="menu"
        [class.is-open]="menuIsOpen"
        [attr.data-e2e]="'user-actions-trigger'"
        (menuOpened)="menuIsOpen = true"
        (menuClosed)="menuIsOpen = false; layoutService.closeSidenav()"
        fxLayoutAlign="space-between center"
        class="px-12 py-8"
        fxFlexFill
        mat-button
      >
        <span
          fxLayoutAlign="start center"
          fxLayoutGap="12px"
          class="user-actions-button-inner"
        >
          <mat-icon svgIcon="fad_user-circle"></mat-icon>
          <span
            fxLayout="column"
            fxLayoutAlign="center start"
          >
            <ng-container
              *ngTemplateOutlet="userProfileFilled ? detailsTemplate : simpleTemplate"
            ></ng-container>

            <ng-template #detailsTemplate>
              <span
                class="text-truncate font-size-sm font-weight-semibold">{{user.contact.firstname}} {{user.contact.lastname}}</span>
              <small
                class="text-truncate font-size-xs">{{user.isCustomer ? user._embedded.customer![0]!.name : user.email}}</small>
            </ng-template>

            <ng-template #simpleTemplate>
              <span class="text-truncate font-size-sm font-weight-semibold">{{user.email}}</span>
            </ng-template>
          </span>
        </span>
      </button>
    </ng-template>
  </div>
</ng-container>
