import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IJbdUiDialog } from '../dialog.interface';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NgComponentOutlet, NgIf } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { JbdUiIconButtonComponent } from '../../button/icon/icon.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'jbd-ui-dialog-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    JbdUiIconButtonComponent,
    MatDialogModule,
    NgComponentOutlet,
    NgIf,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiDialogContainerComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: IJbdUiDialog) {}
}
