import { ICONS } from './icons';
import { ICONS as UI_ICONS } from '@ui/shared/icons/icons';
import { JbdCoreIconService } from '@core/services/icon/icon.service';
import { NgModule } from '@angular/core';

@NgModule({
  exports: [],
})
export class IconsModule {
  constructor(private iconService: JbdCoreIconService) {
    [...ICONS, ...UI_ICONS].forEach((icon) => this.iconService.addIcon(icon));
  }
}
