import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JbdUiStateType } from '../../../shared/misc/types/state';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { JbdUiStateIconComponent } from '../../../elements/state/icon/icon.component';

@Component({
  selector: 'jbd-ui-layout-notification-block',
  templateUrl: './notification-block.component.html',
  styleUrls: ['./notification-block.component.scss'],
  standalone: true,
  imports: [FlexLayoutModule, JbdUiStateIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLayoutNotificationBlockComponent {
  @Input() public e2eId = 'notification-block';
  @Input() public message!: string;
  @Input() public layoutMode: 'dense' | 'default' = 'default';
  @Input() public state!: JbdUiStateType;
  @Input() public title!: string;
}
