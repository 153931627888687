<ng-container
  *transloco="let t"
  [formGroup]="formGroup"
>
  <div
    class="form-group"
    fxLayout="column"
    [ngClass]="{'is-invalid': field?.invalid && field?.touched}"
    [attr.data-e2e]="name + '-form-group'"
  >
    <mat-label
      *ngIf="label"
      [attr.data-e2e]="name + '-label'"
    >
      <span>{{label}}</span>
      <span
        *ngIf="isRequired && !hideAsterisk"
        [attr.data-e2e]="name + '-label-required-asterisk'"
        [title]="t('UI.SHARED.FORM.HINTS.REQUIRED')"
      >&thinsp;*</span>
      <span
        *ngIf="!isRequired && showOptionalHint"
        [attr.data-e2e]="name + '-label-optional-hint'"
      >&nbsp;({{t('UI.SHARED.FORM.HINTS.OPTIONAL')}})</span>
    </mat-label>
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      [ngClass]="{'is-required': isRequired || !isRequired && hideAsterisk}"
    >
      <mat-icon
        *ngIf="prefixIcon"
        [svgIcon]="prefixIcon"
        [attr.data-e2e]="name + '-input-prefix-icon'"
        class="text-monochrome-700 pl-0 pr-8"
        matIconPrefix
      ></mat-icon>
      <input
        [type]="type"
        [formControlName]="name"
        [id]="name"
        [placeholder]="placeholder"
        class="form-control"
        matInput
        [attr.data-e2e]="name + '-input'"
        [attr.autocomplete]="type === 'password' && (name.startsWith('new') || name.startsWith('plain')) ? 'new-password' : ''"
        [required]="isRequired"
      >
      <span
        *ngIf="suffixLabel"
        class="font-weight-semibold pl-8"
        [attr.data-e2e]="name + '-input-suffix'"
        matTextSuffix
      >{{suffixLabel}}</span>

      <button
        *ngIf="suffixButton"
        [ngClass]="suffixButton.class ? suffixButton.class : ''"
        [disabled]="suffixButton.isLoading"
        [attr.data-e2e]="name + '-input-suffix-button'"
        (click)="clickedSuffixButton.emit()"
        mat-icon-button
        type="button"
        matIconSuffix
      >
        <mat-icon
          *ngIf="!suffixButton.isLoading"
          [svgIcon]="suffixButton.icon"
          [attr.data-e2e]="name + '-input-suffix-button-icon'"
        ></mat-icon>

        <mat-spinner
          *ngIf="suffixButton.isLoading"
          [diameter]="16"
          [attr.data-e2e]="name + '-input-suffix-button-loading-indicator'"
        ></mat-spinner>
      </button>

      <mat-hint
        *ngIf="hint"
        class="mt-8"
      >
        <jbd-ui-hint
          [hint]="hint"
          [e2eId]="name + '-hint'"
        ></jbd-ui-hint>
      </mat-hint>
      <mat-error>
        <jbd-ui-form-error
          [errorTranslationPrefix]="errorTranslationPrefix"
          [field]="field"
          [name]="name"
          [patternName]="patternName"
        ></jbd-ui-form-error>
      </mat-error>
    </mat-form-field>
  </div>
</ng-container>
