import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JbdUiButtonAbstractComponent } from '../abstract/abstract.component';
import { JbdUiButtonInnerComponent } from '../button-inner/button-inner.component';
import { MatButtonModule } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'jbd-ui-button-tertiary',
  templateUrl: './tertiary.component.html',
  styleUrls: ['./tertiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JbdUiButtonInnerComponent, MatButtonModule, NgTemplateOutlet],
})
export class JbdUiButtonTertiaryComponent extends JbdUiButtonAbstractComponent {
  @Input() e2eId = 'button-tertiary';
}
