import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreIconService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  public addIcon(faIcon: IconDefinition): void {
    const svg = icon(faIcon).html.join('');
    const sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(svg);
    const iconName = `${faIcon.prefix}_${faIcon.iconName}`;
    this.iconRegistry.addSvgIconLiteral(iconName, sanitizedIcon);
  }
}
