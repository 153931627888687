import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { JbdUiButtonIconPosition } from '../../../shared/misc/types/icon-position';
import { JbdUiLayoutHeaderActionComponent } from './action/action.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgIf } from '@angular/common';

@Component({
  selector: 'jbd-ui-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [JbdUiLayoutHeaderActionComponent, FlexLayoutModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLayoutHeaderComponent {
  @Input() public buttonIcon?: string;
  @Input() public buttonIconPosition: JbdUiButtonIconPosition = 'pre';
  @Input() public buttonLabel?: string;
  @Input() public e2eId = 'layout-header';
  @Input() public title!: string;

  @Output() clicked = new EventEmitter<Event>();
}
