import { NgModule } from '@angular/core';
import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { environment } from '../../environments/environment';
import { TranslocoHttpLoader } from './transloco.loader';
import { getTranslocoConfig } from '@core/shared/config/transloco.config';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: getTranslocoConfig(environment),
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),
  ],
})
export class TranslocoRootModule {}
