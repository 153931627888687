import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { IJbdCoreDataLoginLink } from '@core/services/data/data.interface';
import { IOneTimeLink } from '../shared/interfaces/one-time-link.interface';
import { JbdCoreDataService } from '@core/services/data/data.service';
import { JbdUiAlertType } from '../../../../../shared/misc/types/alert';
import { TranslocoService } from '@jsverse/transloco';
import { JbdUiAccountOneTimeLinkWidgetComponent } from '../widget/widget.component';
import { JbdUiLoaderComponent } from '../../../../../elements/loader/loader.component';
import { NgIf } from '@angular/common';
import { JbdUiAlertComponent } from '../../../../../elements/alert/alert.component';

@Component({
  selector: 'jbd-ui-account-one-time-link-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    JbdUiAccountOneTimeLinkWidgetComponent,
    JbdUiAlertComponent,
    JbdUiLoaderComponent,
    NgIf,
  ],
  animations: [fadeToggle],
})
export class JbdUiAccountOneTimeLinkWrapperComponent {
  public oneTimeLink: IOneTimeLink = {
    state: 'initial',
    response: {
      type: 'success',
      message: '',
    },
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private coreDataService: JbdCoreDataService,
    private translocoService: TranslocoService
  ) {}

  public sendOneTimeLink($event: IJbdCoreDataLoginLink): void {
    this.oneTimeLink.state = 'is-loading';
    this.coreDataService.createLoginLink($event).subscribe({
      next: () => {
        this.oneTimeLink = this.setOneTimeLinkResponse('success', $event.email);
        this.resetOneTimeLink();
        this.cdRef.markForCheck();
      },
      error: () => {
        this.oneTimeLink = this.setOneTimeLinkResponse('danger', $event.email);
        this.resetOneTimeLink();
        this.cdRef.markForCheck();
      },
    });
  }

  private setOneTimeLinkResponse(
    type: JbdUiAlertType,
    emailAddress: string
  ): IOneTimeLink {
    return {
      state: 'submitted',
      response: {
        type,
        message: this.translocoService.translate(
          `UI.ACCOUNT.ONE_TIME.ALERT.${type.toUpperCase()}`,
          { emailAddress }
        ),
      },
    };
  }

  private resetOneTimeLink(): void {
    setTimeout(() => {
      this.oneTimeLink.state = 'initial';
      this.cdRef.markForCheck();
    }, 8000);
  }
}
