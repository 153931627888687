import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateParams, TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreTitleService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private translocoService: TranslocoService
  ) {}

  public setRouteTitle(fallbackRouteTitle: TranslateParams): void {
    this.getRouteTitleFromRouter().subscribe(
      (activatedRoute: ActivatedRoute) => {
        const data = activatedRoute.snapshot.data;

        let routeTitle =
          typeof data.routeTitle === 'function'
            ? data.routeTitle(activatedRoute.snapshot)
            : data.routeTitle;

        routeTitle = routeTitle || fallbackRouteTitle;

        this.translocoService
          .selectTranslate(routeTitle)
          .pipe(first())
          .subscribe((title: string) => {
            this.titleService.setTitle(`JOBODO - ${title}`);
          });
      }
    );
  }

  private getRouteTitleFromRouter(): Observable<ActivatedRoute> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      })
    );
  }
}
