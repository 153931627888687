const JBD_CORE_SCROLL_CONFIG: Partial<ScrollIntoViewOptions> = {
  behavior: 'smooth',
};

export const JBD_CORE_SCROLL_CONFIG_TO_START: Partial<ScrollIntoViewOptions> = {
  ...JBD_CORE_SCROLL_CONFIG,
  block: 'start',
};

export const JBD_CORE_SCROLL_CONFIG_TO_END: Partial<ScrollIntoViewOptions> = {
  ...JBD_CORE_SCROLL_CONFIG,
  block: 'end',
};
