import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DEFAULT_ANIMATION_DURATION } from './animation.config';

export const fadeToggle = trigger('fade-toggle', [
  transition(':enter', [
    style({ opacity: 0, height: '0' }),
    animate(
      `${DEFAULT_ANIMATION_DURATION}ms ease-in`,
      keyframes([
        style({ opacity: 0, height: '0', offset: 0 }),
        style({ opacity: 0, height: '*', offset: 0.25 }),
        style({ opacity: 0.25, height: '*', offset: 0.75 }),
        style({ opacity: 1, height: '*', offset: 1 }),
      ])
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1, height: '*' }),
    animate(
      `${DEFAULT_ANIMATION_DURATION}ms ease-in`,
      keyframes([
        style({ opacity: 1, height: '*', offset: 0 }),
        style({ opacity: 0, height: '*', offset: 0.5 }),
        style({ opacity: 0, height: '0', offset: 1 }),
      ])
    ),
  ]),
]);
