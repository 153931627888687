<div
  *ngIf="oneTimeLink.state !== 'submitted'; else alertTemplate"
  @fade-toggle
  class="one-time-link-wrapper"
  [class.is-disabled]="oneTimeLink.state === 'is-loading'">
  <jbd-ui-account-one-time-link-widget
    (clicked)="sendOneTimeLink($event)"
  ></jbd-ui-account-one-time-link-widget>
  <jbd-ui-loader
    *ngIf="oneTimeLink.state === 'is-loading'"
    [e2eId]="'one-time-link-wrapper-loader'"
  ></jbd-ui-loader>
</div>

<ng-template #alertTemplate>
  <jbd-ui-alert
    [message]="oneTimeLink.response.message"
    [e2eId]="'one-time-link-wrapper-alert'"
    [type]="$any(oneTimeLink.response.type)"
  ></jbd-ui-alert>
</ng-template>
