import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IJbdEmbeddedAttribute } from '../../shared/misc/interfaces/embedded-attribute.interface';
import { IJbdUser } from '../../shared/misc/interfaces/user.interface';
import { API_SCOPE, SHARED_API_PATHS } from './data.endpoints';
import {
  IJbdCoreDataActivationRequestPayload,
  IJbdCoreDataLoginLink,
  IJbdCoreDataLoginRequestPayload,
  IJbdCoreDataOptionResponse,
  IJbdCoreDataRegisterRequestPayload,
  IJbdCoreDataUpdateMeRequestPayload,
  IJbdCoreDataUpdatePasswordRequestPayload,
  IJbdCorePathVariables,
} from './data.interface';
import { replacePathSegmentWithId } from './data.utils';
import { IJbdDocumentLink } from '../../shared/misc/interfaces/document-links.interface';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreDataService {
  constructor(private http: HttpClient) {}

  private getUrlPath(
    path: string,
    apiPathsObj: { [key: string]: string | { [key: string]: string } },
    { optionKey }: IJbdCorePathVariables
  ): string {
    let formattedPath: string = path
      .split('.')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .reduce(
        (p: { [index: string]: never }, c) => p?.[c] || null,
        apiPathsObj
      );

    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'optionKey',
      optionKey
    );

    return formattedPath;
  }

  private buildUrlFor(
    scope: string,
    path: string,
    pathVariables?: IJbdCorePathVariables
  ): string {
    const urlPath = this.getUrlPath(
      path,
      SHARED_API_PATHS,
      pathVariables ?? {}
    );

    return `/${(API_SCOPE as { [key: string]: string })[scope]}/${urlPath}`;
  }

  // user

  public register(
    requestPayload: IJbdCoreDataRegisterRequestPayload
  ): Observable<IJbdUser> {
    return this.http.post<IJbdUser>(
      this.buildUrlFor('DATA', 'USERS.REGISTER'),
      requestPayload
    );
  }

  public activate(
    requestPayload: IJbdCoreDataActivationRequestPayload
  ): Observable<IJbdUser> {
    return this.http.post<IJbdUser>(
      this.buildUrlFor('DATA', 'USERS.ACTIVATE'),
      requestPayload
    );
  }

  public acceptInvite(
    requestPayload: IJbdCoreDataActivationRequestPayload
  ): Observable<IJbdUser> {
    return this.http.patch<IJbdUser>(
      this.buildUrlFor('DATA', 'USERS.ACCEPT_INVITE'),
      requestPayload
    );
  }

  public createLoginLink(params: IJbdCoreDataLoginLink): Observable<void> {
    return this.http.post<void>(this.buildUrlFor('AUTH', 'LOGIN_LINK'), params);
  }

  public login(
    requestPayload: IJbdCoreDataLoginRequestPayload
  ): Observable<void> {
    return this.http.post<void>(
      this.buildUrlFor('AUTH', 'LOGIN'),
      requestPayload
    );
  }

  public loginViaLink(paramString: string): Observable<void> {
    return this.http.get<void>(
      `${this.buildUrlFor('AUTH', 'LINK')}${paramString}`
    );
  }

  public getMe(): Observable<IJbdUser> {
    return this.http.get<IJbdUser>(this.buildUrlFor('DATA', 'USERS.ME'));
  }

  public updateMe(
    params: IJbdCoreDataUpdateMeRequestPayload
  ): Observable<IJbdUser> {
    return this.http.patch<IJbdUser>(
      this.buildUrlFor('DATA', 'USERS.ME'),
      params
    );
  }

  public addUserAttribute(params: IJbdEmbeddedAttribute): Observable<IJbdUser> {
    return this.http.post<IJbdUser>(
      this.buildUrlFor('DATA', 'USERS.ATTRIBUTES'),
      params
    );
  }

  public updatePassword(
    params: IJbdCoreDataUpdatePasswordRequestPayload
  ): Observable<IJbdUser> {
    return this.http.patch<IJbdUser>(
      this.buildUrlFor('DATA', 'USERS.PASSWORD'),
      params
    );
  }

  // options

  public getOptions(): Observable<IJbdCoreDataOptionResponse[]> {
    return this.http.get<IJbdCoreDataOptionResponse[]>(
      this.buildUrlFor('DATA', 'OPTIONS')
    );
  }

  // documents

  public getDocuments(): Observable<IJbdDocumentLink[]> {
    return this.http.get<IJbdDocumentLink[]>(
      this.buildUrlFor('DATA', 'DOCUMENTS')
    );
  }
}
