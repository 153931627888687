import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgIf } from '@angular/common';

@Component({
  selector: 'jbd-ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, FlexLayoutModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLoaderComponent {
  @Input() public color: ThemePalette = 'accent';
  @Input() public diameter = 48;
  @Input() public type: 'inline' | 'page' = 'page';
  @Input() public e2eId = 'loader';
  @Input() public label?: string;
}
