import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { JbdCoreDataService } from '../data/data.service';
import { IJbdDocumentLink } from '../../shared/misc/interfaces/document-links.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreDocumentsService {
  private documents = new ReplaySubject<IJbdDocumentLink[]>(1);
  private documents$ = this.documents.asObservable();

  constructor(private dataService: JbdCoreDataService) {}

  private setToStorage(documents: IJbdDocumentLink[]): void {
    sessionStorage.setItem(
      'documents',
      btoa(unescape(encodeURIComponent(JSON.stringify(documents))))
    );
  }

  private getFromStorage(): IJbdDocumentLink[] {
    const documents = sessionStorage.getItem('documents') ?? '';

    if (documents) {
      return JSON.parse(decodeURIComponent(escape(atob(documents))));
    }

    return [];
  }

  private update(documents: IJbdDocumentLink[]): void {
    this.documents.next(documents);
  }

  public onUpdate(): Observable<IJbdDocumentLink[]> {
    return this.documents$;
  }

  public init(): void {
    if (this.getFromStorage().length) {
      this.update(this.getFromStorage());

      return;
    }

    this.getFromRemote();
  }

  private getFromRemote(): void {
    this.dataService.getDocuments().subscribe({
      next: this.handleSuccessResponse.bind(this),
      error: this.handleErrorResponse.bind(this),
    });
  }

  private handleSuccessResponse(documents: IJbdDocumentLink[]): void {
    this.setToStorage(documents);
    this.update(documents);
  }

  private handleErrorResponse(error: HttpErrorResponse): void {
    this.documents.error(error);
  }
}
