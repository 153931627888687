<header
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <h1
    class="mat-headline-5 m-0"
    [attr.data-e2e]="e2eId + '-title'"
  >{{title}}</h1>

  <jbd-ui-layout-header-action
    *ngIf="buttonLabel"
    [buttonLabel]="buttonLabel"
    [buttonIcon]="buttonIcon"
    [buttonIconPosition]="buttonIconPosition"
    (clicked)="clicked.emit($event)"
  ></jbd-ui-layout-header-action>
</header>
<hr class="headline mt-8 mb-24 mt-sm-16 mb-sm-32 mb-lg-48">
