import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { transitionMessages } from '@core/utils/animations/transition-message.animation';
import { JbdUiFormAbstractFieldComponent } from '../abstract/abstract-field.component';
import { JbdUiFormRequiredService } from '../../shared/services/required/required.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { JbdUiHintComponent } from '../../../../elements/hint/hint.component';
import { JbdUiFormErrorComponent } from '../../shared/components/error/error.component';

@Component({
  selector: 'jbd-ui-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  animations: [transitionMessages],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    NgIf,
    FlexLayoutModule,
    TranslocoModule,
    MatCheckboxModule,
    NgSwitch,
    JbdUiHintComponent,
    NgSwitchCase,
    JbdUiFormErrorComponent,
  ],
})
export class JbdUiFormFieldCheckboxComponent
  extends JbdUiFormAbstractFieldComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  private linksInsideLabel?: NodeList;
  public subscriptAnimationState = 'enter';

  @Input() public inlineLabel!: string;

  constructor(private formService: JbdUiFormRequiredService) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isRequired = this.formService.initRequiredStatus(
      this.formGroup,
      this.name
    );
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.linksInsideLabel = document.querySelectorAll(
        '.mat-checkbox-inline-label > a'
      );

      this.linksInsideLabel?.forEach((link: Node) => {
        link.addEventListener('mousedown', this.handleLinkAlternatively);
        link.addEventListener('touchstart', this.handleLinkAlternatively);
      });
    });
  }

  private handleLinkAlternatively(e: Event): void {
    const target = e.target as HTMLAnchorElement;
    e.preventDefault();
    e.stopPropagation();
    window.open(target.href, '_blank');
  }

  public ngOnDestroy(): void {
    this.linksInsideLabel?.forEach((link: Node) => {
      link.removeEventListener('mousedown', this.handleLinkAlternatively);
      link.removeEventListener('touchstart', this.handleLinkAlternatively);
    });
  }
}
