import { IAppDetails } from '../../shared/misc/interfaces/app-details.interface';
import { Inject, Injectable } from '@angular/core';
import { JbdCoreRedirectService } from '../redirect/redirect.service';
import { JbdCoreUserService } from '../user/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class JbdCorePasswordResetService {
  private readonly redirectDelay = 5000;

  constructor(
    @Inject('environment') private environment: { appDetails: IAppDetails },
    private router: Router,
    private redirectService: JbdCoreRedirectService,
    private userService: JbdCoreUserService
  ) {}

  private setPasswordResetToken(token: string) {
    window.sessionStorage.setItem('passwordResetToken', token);
  }

  public getPasswordResetToken(): string | null {
    return window.sessionStorage.getItem('passwordResetToken');
  }

  public clearPasswordResetToken() {
    window.sessionStorage.removeItem('passwordResetToken');
  }

  public preparePasswordResetRedirect(token: string) {
    JbdCoreRedirectService.setRedirectUrl(
      this.environment.appDetails.urls.passwordReset
    );
    this.setPasswordResetToken(token);
  }

  public userWillBeRedirected(): boolean {
    return (
      !this.userService.isUserProfileFilled() &&
      this.redirectService.getRedirectUrl() ===
        this.environment.appDetails.urls.passwordReset
    );
  }

  public redirectToOnboarding() {
    setTimeout(() => {
      this.router
        .navigate([this.environment.appDetails.urls?.onboarding])
        .then(() => {
          JbdCoreRedirectService.clearRedirectUrl();
          this.clearPasswordResetToken();
        });
    }, this.redirectDelay);
  }
}
