import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_SCOPE } from '@core/services/data/data.endpoints';
import {
  appendFormData,
  replacePathSegmentWithId,
  setFormDataHeaders,
} from '@core/services/data/data.utils';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { IJbdEmbeddedAddress } from '@core/shared/misc/interfaces/adress.interface';
import { IJbdEmbeddedAttribute } from '@core/shared/misc/interfaces/embedded-attribute.interface';
import { IJbdFile } from '@core/shared/misc/interfaces/file.interface';
import {
  IJbdUser,
  IJbdUserListItem,
} from '@core/shared/misc/interfaces/user.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IJbdLocationType } from '../../misc/interfaces/location-type.interface';
import {
  IJbdLocation,
  IJbdLocationListItem,
  IJbdLocationTransition,
  IJbdPublicLocation,
} from '../../misc/interfaces/location.interface';
import { PARTNER_API_PATHS } from './data.endpoints';
import {
  IJbdDataAddLocationBankDetailsRequestPayload,
  IJbdDataCreateLocationRequestPayload,
  IJbdDataPatchLocationFileRequestPayload,
  IJbdDataUpdateBookingState,
  IJbdDataUpdateLocationRequestPayload,
  IJbdDataUserManagementUpsertUserRequestPayload,
  IJbdPathVariables,
} from './data.interface';
import { IJbdPayout } from '../../misc/interfaces/payout.interface';
import { JbdLocationBookingTransitionType } from '../../misc/types/booking-transition';
import { IJbdLocationBooking } from '../../misc/interfaces/booking.interface';
import { IJbdLocationVacation } from '../../misc/interfaces/vacation.interface';
import { IJbdSuggestedImage } from '@core/shared/misc/interfaces/suggested-image.interface';

@Injectable({
  providedIn: 'root',
})
export class JbdDataService {
  constructor(
    private http: HttpClient,
    private userService: JbdCoreUserService
  ) {}

  private replacePartnerId(path: string, partnerId?: string): string {
    if (!path.includes(':partnerId')) {
      return path;
    }

    return path.replace(':partnerId', partnerId || this.userService.partnerId);
  }

  private getUrlPath(
    path: string,
    apiPathsObj: { [key: string]: string | { [key: string]: string } },
    {
      addressId,
      bookingId,
      creditId,
      convertBookingId,
      fileId,
      locationId,
      partnerId,
      userId,
      vacationId,
    }: IJbdPathVariables
  ): string {
    let formattedPath: string = path
      .split('.')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .reduce(
        (p: { [index: string]: never }, c) => p?.[c] || null,
        apiPathsObj
      );

    formattedPath = this.replacePartnerId(formattedPath, partnerId);
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'locationId',
      locationId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'addressId',
      addressId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'vacationId',
      vacationId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'convertBookingId',
      convertBookingId
    );
    formattedPath = replacePathSegmentWithId(formattedPath, 'fileId', fileId);
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'bookingId',
      bookingId
    );
    formattedPath = replacePathSegmentWithId(formattedPath, 'userId', userId);
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'creditId',
      creditId
    );

    return formattedPath;
  }

  private buildUrlFor(
    scope: string,
    path: string,
    pathVariables?: IJbdPathVariables
  ): string {
    const urlPath = this.getUrlPath(
      path,
      PARTNER_API_PATHS,
      pathVariables ?? {}
    );

    return `/${(API_SCOPE as { [key: string]: string })[scope]}/${urlPath}`;
  }

  // locations

  public getLocations(): Observable<IJbdLocationListItem[]> {
    return this.http.get<IJbdLocationListItem[]>(
      this.buildUrlFor('DATA', 'LOCATION.LIST')
    );
  }

  public getLocation(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.get<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.DETAILS', pathVariables)
    );
  }

  public createLocation(
    requestPayload: IJbdDataCreateLocationRequestPayload
  ): Observable<IJbdLocation> {
    return this.http.post<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.LIST'),
      requestPayload
    );
  }

  public updateLocation(
    requestPayload: IJbdDataUpdateLocationRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.patch<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.DETAILS', pathVariables),
      requestPayload
    );
  }

  public createLocationAddress(
    requestPayload: IJbdEmbeddedAddress,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.post<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.ADDRESSES', pathVariables),
      requestPayload
    );
  }

  public updateLocationAddress(
    requestPayload: IJbdEmbeddedAddress,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.patch<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.ADDRESS', pathVariables),
      requestPayload
    );
  }

  public addLocationAttribute(
    params: IJbdEmbeddedAttribute,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.post<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.ATTRIBUTES', pathVariables),
      params
    );
  }

  public getLocationTypes(): Observable<IJbdLocationType[]> {
    return this.http.get<IJbdLocationType[]>(
      this.buildUrlFor('DATA', 'LOCATION.TYPES')
    );
  }

  public getSuggestedLocationImages(
    pathVariables: IJbdPathVariables
  ): Observable<Omit<IJbdSuggestedImage, 'selected'>[]> {
    return this.http.get<Omit<IJbdSuggestedImage, 'selected'>[]>(
      this.buildUrlFor('DATA', 'LOCATION.SUGGESTED_IMAGES', pathVariables)
    );
  }

  public addLocationFile(
    requestPayload: IJbdFile,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    const formData = appendFormData(requestPayload, new FormData());
    const headers = setFormDataHeaders();
    const url = this.buildUrlFor('DATA', 'LOCATION.FILES', pathVariables);

    return this.http.post<IJbdLocation>(url, formData, { headers });
  }

  public patchLocationFile(
    requestPayload: IJbdDataPatchLocationFileRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.patch<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.FILE_DETAILS', pathVariables),
      requestPayload
    );
  }

  public deleteLocationFile(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.delete<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.FILE_DETAILS', pathVariables)
    );
  }

  public getContractPdf(pathVariables: IJbdPathVariables): string {
    return `${environment.serverUrl}${this.buildUrlFor(
      'DATA',
      'LOCATION.CONTRACT',
      pathVariables
    )}`;
  }

  public updateLocationTransition(
    requestPayload: IJbdLocationTransition,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.patch<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.DETAILS', pathVariables),
      requestPayload
    );
  }

  public addLocationBankDetails(
    requestPayload: IJbdDataAddLocationBankDetailsRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocation> {
    return this.http.post<IJbdLocation>(
      this.buildUrlFor('DATA', 'LOCATION.BANK_DETAILS', pathVariables),
      requestPayload
    );
  }

  // user-management

  public getUserList(): Observable<IJbdUserListItem[]> {
    return this.http.get<IJbdUserListItem[]>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.LIST')
    );
  }

  public addUser(
    requestPayload: IJbdDataUserManagementUpsertUserRequestPayload
  ): Observable<IJbdUserListItem> {
    return this.http.post<IJbdUserListItem>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.LIST'),
      requestPayload
    );
  }

  public updateUser(
    requestPayload: IJbdDataUserManagementUpsertUserRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdUser> {
    return this.http.patch<IJbdUser>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.DETAILS', pathVariables),
      requestPayload
    );
  }

  public deleteUser(pathVariables: IJbdPathVariables): Observable<IJbdUser[]> {
    return this.http.delete<IJbdUser[]>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.DETAILS', pathVariables)
    );
  }

  // credits

  public getPayouts(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdPayout[]> {
    return this.http.get<IJbdPayout[]>(
      this.buildUrlFor('DATA', 'PAYOUTS.LIST', pathVariables)
    );
  }

  public getPayoutPdf(pathVariables: IJbdPathVariables): string {
    return `${environment.serverUrl}${this.buildUrlFor(
      'DATA',
      'PAYOUTS.PAYOUT_DOWNLOAD',
      pathVariables
    )}`;
  }

  // receipt

  public getPublicLocationDetails(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdPublicLocation> {
    return this.http.get<IJbdPublicLocation>(
      this.buildUrlFor('DATA', 'RECEIPT.DETAILS', pathVariables)
    );
  }

  public updatePublicLocationState(
    requestPayload: { transition: JbdLocationBookingTransitionType },
    pathVariables: IJbdPathVariables
  ): Observable<IJbdPublicLocation> {
    return this.http.patch<IJbdPublicLocation>(
      this.buildUrlFor('DATA', 'RECEIPT.DETAILS', pathVariables),
      requestPayload
    );
  }

  public addPublicFile(
    requestPayload: IJbdFile,
    pathVariables: IJbdPathVariables
  ): Observable<HttpEvent<IJbdPublicLocation>> {
    const formData = appendFormData(requestPayload, new FormData());
    const headers = setFormDataHeaders();
    const url = this.buildUrlFor('DATA', 'RECEIPT.FILES', pathVariables);

    return this.http.post<IJbdPublicLocation>(url, formData, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  public convertBooking(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdPathVariables> {
    return this.http.get<IJbdPathVariables>(
      this.buildUrlFor('DATA', 'RECEIPT.CONVERT', pathVariables)
    );
  }

  // bookings

  public getBookings(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationBooking[]> {
    return this.http.get<IJbdLocationBooking[]>(
      this.buildUrlFor('DATA', 'BOOKING.LIST', pathVariables)
    );
  }

  public getBooking(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationBooking> {
    return this.http.get<IJbdLocationBooking>(
      this.buildUrlFor('DATA', 'BOOKING.DETAILS', pathVariables)
    );
  }

  public getOpenBookingTask(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationBooking | null> {
    return this.http.get<IJbdLocationBooking | null>(
      this.buildUrlFor('DATA', 'BOOKING.TASK', pathVariables)
    );
  }

  public updateBookingState(
    requestPayload: IJbdDataUpdateBookingState,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationBooking> {
    return this.http.patch<IJbdLocationBooking>(
      this.buildUrlFor('DATA', 'BOOKING.DETAILS', pathVariables),
      requestPayload
    );
  }

  public getInfoCard(pathVariables: IJbdPathVariables): string {
    return `${environment.serverUrl}${this.buildUrlFor(
      'DATA',
      'BOOKING.INFO_CARD',
      pathVariables
    )}`;
  }

  // vacation

  public getVacations(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationVacation[]> {
    return this.http.get<IJbdLocationVacation[]>(
      this.buildUrlFor('DATA', 'VACATION.LIST', pathVariables)
    );
  }

  public addVacation(
    requestPayload: IJbdLocationVacation,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationVacation> {
    return this.http.post<IJbdLocationVacation>(
      this.buildUrlFor('DATA', 'VACATION.LIST', pathVariables),
      requestPayload
    );
  }

  public updateVacation(
    requestPayload: IJbdLocationVacation,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdLocationVacation> {
    return this.http.patch<IJbdLocationVacation>(
      this.buildUrlFor('DATA', 'VACATION.DETAILS', pathVariables),
      requestPayload
    );
  }

  public deleteVacation(pathVariables: IJbdPathVariables): Observable<void> {
    return this.http.delete<void>(
      this.buildUrlFor('DATA', 'VACATION.DETAILS', pathVariables)
    );
  }
}
