import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { JbdCoreDataService } from '@core/services/data/data.service';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { IJbdUser } from '@core/shared/misc/interfaces/user.interface';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { TranslocoService } from '@jsverse/transloco';
import { IJbdAlert } from '@ui/elements/alert/alert.interface';
import { switchMap, tap } from 'rxjs/operators';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Component({
  selector: 'jbd-app-account-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
  animations: [fadeToggle],
})
export class JbdAccountActivateComponent implements OnInit {
  public alert: IJbdAlert = {
    isVisible: false,
    message: '',
    type: 'danger',
  };

  @ViewChild('alertElement', { static: false })
  public alertElement!: ElementRef;

  constructor(
    private coreDataService: JbdCoreDataService,
    private route: ActivatedRoute,
    private router: Router,
    private userEventService: JbdCoreUserEventService,
    private translocoService: TranslocoService,
    private userService: JbdCoreUserService
  ) {}

  public ngOnInit(): void {
    if (this.userService.isLoggedIn()) {
      this.redirectUser();

      return;
    }

    this.handleActivationLink();
  }

  private redirectUser() {
    void this.router.navigate([this.userService.getUserRoute()]);
  }

  private handleActivationLink() {
    const { queryParams } = this.route.snapshot;

    if (
      !(
        window.location.search &&
        'confirmationToken' in queryParams &&
        'user' in queryParams &&
        'hash' in queryParams &&
        'expires' in queryParams
      )
    ) {
      return;
    }

    this.handleSubmit(queryParams);
  }

  private handleSubmit(params: Params) {
    const { confirmationToken } = params;

    this.coreDataService
      .loginViaLink(window.location.search)
      .pipe(
        switchMap(() => this.coreDataService.activate({ confirmationToken })),
        tap((user: IJbdUser) => this.userService.setUser(user)),
        tap((user: IJbdUser) => this.userEventService.triggerLogin(user))
      )
      .subscribe({
        next: () => this.redirectUser(),
        error: (error: HttpErrorResponse) => this.handleErrorResponse(error),
      });
  }

  private handleErrorResponse(error: HttpErrorResponse) {
    let translation = 'GENERIC';
    if (error.status === 498) {
      translation = 'LINK_EXPIRED';
    } else if (error.status === 400) {
      translation = 'ALREADY_ACTIVATED';
    }

    this.alert.message = this.translocoService.translate(
      `ACCOUNT.ACTIVATE.ALERT.ERROR.${translation}`
    );
    this.alert.isVisible = true;
    this.alertElement.nativeElement.scrollIntoView(
      JBD_CORE_SCROLL_CONFIG_TO_END
    );
  }
}
