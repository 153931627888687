import {
  ChangeDetectionStrategy,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { IJbdLocationListItem } from '../../../shared/misc/interfaces/location.interface';
import { JbdUiLayoutService } from '@ui/compositions/layout/shared/layout/layout.service';

@Component({
  selector: 'jbd-app-navigation-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdNavigationAdminViewComponent implements AfterViewInit {
  public animationsDisabled = true;

  constructor(
    private cdRef: ChangeDetectorRef,
    public layoutService: JbdUiLayoutService
  ) {}

  @Input() public locations?: IJbdLocationListItem[];

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.animationsDisabled = false;
      this.cdRef.markForCheck();
    }, 225);
  }
}
