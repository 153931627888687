import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DEFAULT_ANIMATION_DURATION } from './animation.config';

export const transitionMessages = trigger('transition-messages', [
  state('enter', style({ opacity: 1, transform: 'translateY(0%)' })),
  transition('void => enter', [
    style({ opacity: 0, transform: 'translateY(-5px)' }),
    animate(`${DEFAULT_ANIMATION_DURATION}ms cubic-bezier(0.55, 0, 0.55, 0.2)`),
  ]),
]);
