<ng-container *transloco="let t">
  <ng-container *ngIf="locations && locations.length > 0">
    <h6 class="mx-16">{{ t('NAVIGATION.LIST.LOCATIONS.HEADER')}}</h6>

    <mat-nav-list
      [attr.data-e2e]="'partner-menu'"
      class="p-0 pr-16"
      fxLayout="column"
      fxLayoutGap="4px"
    >
      <ng-container *ngFor="let location of locations">
        <a
          mat-list-item
          [routerLink]="['/mein-unternehmen/standort', location.id, 'buchungsuebersicht']"
          (click)="layoutService.closeSidenav()"
          routerLinkActive="is-active"
        >
        <span
          class="text-monochrome-900 font-size-xs font-weight-bold"
          matListItemTitle
        >{{location.name}}</span>
        </a>
      </ng-container>
    </mat-nav-list>
  </ng-container>
</ng-container>
