export const API_SCOPE = {
  AUTH: 'auth/1.0',
  DATA: 'api/1.0',
};
export const SHARED_API_PATHS = {
  LOGIN: 'login',
  LOGIN_LINK: 'loginLink',
  LINK: 'link',
  USERS: {
    REGISTER: 'users',
    ACTIVATE: 'users/me/activate',
    ACCEPT_INVITE: 'users/me/acceptInvite',
    ME: 'users/me',
    ATTRIBUTES: 'users/me/attributes',
    PASSWORD: 'users/me/changePassword',
  },
  OPTIONS: 'options',
  OPTION: 'options/:optionKey',
  DOCUMENTS: 'public/documents',
};
