import { environment } from './environments/environment';
import { JbdAppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { VERSION } from './environments/version';
import { enableSentry } from '@core/utils/sentry/sentry.handler';

if (environment.name !== 'local' && environment.name !== 'production') {
  enableSentry({
    dsn: environment.sentryId,
    release: VERSION,
    environment: environment.name,
    tracesSampleRate: environment.sentryTracesSampleRate,
    serverUrl: environment.serverUrl,
  });
}

platformBrowserDynamic()
  .bootstrapModule(JbdAppModule)
  .catch((err) => console.error(err));
