import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { JbdUiButtonType } from '../../../shared/misc/types/button';
import { ExtendedThemePalette } from '../../../shared/misc/types/extended-theme-palette';
import { JbdUiButtonIconPosition } from '../../../shared/misc/types/icon-position';
import { JbdUiSizeType } from '../../../shared/misc/types/size';

@Directive()
export abstract class JbdUiButtonAbstractComponent {
  @Input() color: ExtendedThemePalette;
  @Input() link!: string;
  @Input() label!: string;
  @Input() isLoading = false;
  @HostBinding('class.auto-stretch') @Input() autoStretch = false;
  @HostBinding('class.auto-stretch-on-mobile') @Input() autoStretchOnMobile =
    false;
  @Input() type: JbdUiButtonType = 'button';
  @Input() icon: any;
  @Input() iconPosition: JbdUiButtonIconPosition = 'pre';
  @Input() iconColor?: ExtendedThemePalette;
  @Input() hasInitialFocus?: boolean;
  @Input() disabled?: boolean;
  @Input() form?: string;
  @Input() classList?: string[];
  @Input() size: JbdUiSizeType = 'default';
  @Input() customButtonInner = false;

  @Output() clicked = new EventEmitter<Event>();
}
