import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { JbdCoreUserRoleService } from '@core/services/user-role/user-role.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IJbdLocationListItem } from '../../shared/misc/interfaces/location.interface';
import { JbdLocationsService } from '../../shared/services/locations/locations.service';

@Component({
  selector: 'jbd-app-navigation',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdNavigationPageComponent implements OnDestroy, OnInit {
  private ngUnsubscribe = new Subject<void>();
  public isAdmin = false;
  public locations?: IJbdLocationListItem[];

  constructor(
    private cdRef: ChangeDetectorRef,
    private locationsService: JbdLocationsService,
    private roleService: JbdCoreUserRoleService
  ) {}

  public ngOnInit(): void {
    this.setPermissions();
    this.subscribeToLocationsUpdates();
  }

  private setPermissions() {
    this.isAdmin = this.roleService.hasRole('ROLE_PARTNER_ADMIN');
  }

  private subscribeToLocationsUpdates(): void {
    this.locationsService
      .onLocationsUpdate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (locations: IJbdLocationListItem[] | undefined) => {
          if (!locations) {
            this.locationsService.loadLocations();
            return;
          }

          this.setLocationList(locations);
        },
      });
  }

  private setLocationList(locations: IJbdLocationListItem[] | undefined): void {
    this.locations = locations;
    this.cdRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this.locationsService.clearLocations();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
