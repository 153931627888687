<button
  [color]="$any(color)"
  [disabled]="disabled"
  (click)="clicked.emit($event)"
  [attr.type]="type"
  [attr.cdkFocusInitial]="hasInitialFocus"
  [attr.form]="form"
  [attr.data-e2e]="e2eId"
  class="is-{{ size }} {{classList?.join(' ')}}"
  mat-flat-button
>
  <jbd-ui-button-inner
    [icon]="icon"
    [iconPosition]="iconPosition"
    [iconColor]="iconColor"
    [isLoading]="isLoading"
    [label]="label"
    [size]="size"
    [e2eId]="e2eId"
  ></jbd-ui-button-inner>
</button>
