import {
  ActivatedRouteSnapshot,
  Params,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { IAppDetails } from '../../shared/misc/interfaces/app-details.interface';
import { Inject, Injectable } from '@angular/core';
import { JbdCoreRedirectService } from '../redirect/redirect.service';
import { JbdCoreUserRoleService } from '../user-role/user-role.service';
import { JbdCoreUserService } from '../user/user.service';
import { JbdCoreUserEventService } from '../user-event/user-event.service';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreAuthGuard {
  constructor(
    @Inject('environment') private environment: { appDetails: IAppDetails },
    private router: Router,
    private userRoleService: JbdCoreUserRoleService,
    private userEventService: JbdCoreUserEventService,
    private userService: JbdCoreUserService
  ) {}

  public canMatch(route: Route, segments: UrlSegment[]): boolean {
    const url = segments.reduce((url, { path }) => `${url}/${path}`, '');
    const queryParams =
      this.router.getCurrentNavigation()?.extractedUrl.queryParams;
    return this.checkLogin(url, route, queryParams);
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkLogin(state.url, route);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  }

  protected checkLogin(
    url: string,
    route: ActivatedRouteSnapshot | Route,
    queryParams?: Params
  ): boolean {
    if (!this.userService.isLoggedIn()) {
      JbdCoreRedirectService.setRedirectUrl(url);
      void this.router.navigate([this.environment.appDetails.urls?.login], {
        queryParams,
      });
      this.userEventService.triggerLogout();

      return false;
    }

    if (this.userRoleService.hasRoles(route.data?.roles)) {
      return true;
    }

    JbdCoreRedirectService.clearRedirectUrl();
    void this.router.navigate([this.userService.getUserRoute()], {
      queryParams,
    });

    return false;
  }
}
