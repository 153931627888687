/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum JbdCalendarEventIconEnum {
  RESERVED = 'far_circle',
  BOOKED = 'far_circle',
  SUGGESTED = 'fas_arrow-right',
  ACCEPTED = 'fas_arrow-right',
  SENT = 'fas_arrow-right',
  RECEIVED = 'fas_arrow-right',
  PLACED = 'fas_check',
  RUNNING = 'fas_check',
  FINISHED = 'fas_check-double',
  VACATION = 'far_clock',
}
