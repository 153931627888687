import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IJbdUiDialog } from '@ui/elements/dialog/dialog.interface';
import { JbdUiDialogContainerComponent } from '@ui/elements/dialog/container/container.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'jbd-app-shared-crop-image-dialog',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdSharedCropImageDialogComponent {
  public croppedImage = '';
  public isLoadingFile = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IJbdUiDialog,
    public dialogRef: MatDialogRef<JbdUiDialogContainerComponent>
  ) {}
}
