<ng-container *transloco="let t">
  <div class="fixed-width-container-sm">
    <jbd-ui-layout-header
      [title]="t('ACCOUNT.LOGIN.TITLE')"
    ></jbd-ui-layout-header>

    <div class="jbd-card">
      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
      >
        <p class="mr-8">{{ t('ACCOUNT.LOGIN.NO_USER_ACCOUNT.MESSAGE') }}</p>
        <a routerLink="/benutzerkonto/registrieren">
          <span>{{ t('CORE.ACCOUNT.LOGIN.NO_USER_ACCOUNT.LINK') }}</span>
          <mat-icon
            svgIcon="fas_chevron-right"
            class="ml-4"
          ></mat-icon>
        </a>
      </div>

      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        [ngClass]="{ 'is-disabled': isLoading }"
        jbdUiFocusFirstInvalidField
        fxLayout="column"
        fxLayoutAlign="start"
        novalidate
      >
        <div
          class="pt-32"
          #alertElement
        >
          <jbd-ui-alert
            *ngIf="alert.isVisible"
            @fade-toggle
            [type]="alert.type"
            [message]="alert.message"
            [isDismissible]="alert.isDismissible"
            (dismissed)="alert.isVisible = false"
            class="block mb-32"
          ></jbd-ui-alert>
        </div>

        <div class="mb-32">
          <jbd-ui-form-input
            [type]="'email'"
            [label]="t('UI.SHARED.FORM.LABELS.EMAIL')"
            [errorTranslationPrefix]="'UI.SHARED.FORM.ERROR.INFORMALLY.'"
            [formGroup]="loginForm"
            [patternName]="'username'"
            [name]="'username'"
          ></jbd-ui-form-input>
        </div>

        <div
          fxLayout="column"
          class="mb-48"
        >
          <jbd-ui-form-input
            [type]="passwordFieldType"
            [label]="t('UI.SHARED.FORM.LABELS.PASSWORD')"
            [suffixButton]="{
              icon: passwordFieldType === 'password' ? 'fas_eye' : 'fas_eye-slash'
            }"
            (clickedSuffixButton)="passwordFieldType = passwordFieldType === 'password'
            ? 'text'
            : 'password'"
            [errorTranslationPrefix]="'UI.SHARED.FORM.ERROR.INFORMALLY.'"
            [formGroup]="loginForm"
            [name]="'password'"
          ></jbd-ui-form-input>
          <a
            fxFlexAlign="end"
            routerLink="/benutzerkonto/passwort-vergessen"
          >{{t('CORE.ACCOUNT.LOGIN.FORM.FIELD.PASSWORD.LINK')}}</a>
        </div>

        <jbd-ui-button-primary
          [label]="t('CORE.ACCOUNT.LOGIN.FORM.ACTION.PRIMARY')"
          [autoStretch]="true"
          [isLoading]="isLoading"
          [disabled]="isLoading"
          type="submit"
        ></jbd-ui-button-primary>
      </form>

      <hr />

      <jbd-ui-account-one-time-link-wrapper></jbd-ui-account-one-time-link-wrapper>
    </div>

    <jbd-ui-account-legal-links
      [legalLinks]="(legalLinks$ | async)!"
    ></jbd-ui-account-legal-links>
  </div>

</ng-container>
