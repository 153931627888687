<button
  mat-icon-button
  [attr.aria-label]="ariaLabel"
  [attr.data-e2e]="e2eId"
  [color]="$any(color)"
  [disabled]="disabled"
  [type]="type"
  class="is-{{ size }} {{classList?.join(' ')}}"
  (click)="clicked.emit($event)">
  <mat-icon
    [svgIcon]="icon"
    [attr.data-e2e]="e2eId + '-icon'"
  ></mat-icon>
</button>
