import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { JbdUiButtonType } from '../../../shared/misc/types/button';
import { ExtendedThemePalette } from '../../../shared/misc/types/extended-theme-palette';
import { JbdUiSizeType } from '../../../shared/misc/types/size';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'jbd-ui-icon-button',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
})
export class JbdUiIconButtonComponent {
  @Input() public ariaLabel = '';
  @Input() public disabled = false;
  @Input() public color: ExtendedThemePalette = 'primary';
  @Input() public e2eId = 'icon-button';
  @Input() public icon!: string;
  @Input() public size: JbdUiSizeType = 'default';
  @Input() public type: JbdUiButtonType = 'button';
  @Input() classList?: string[];

  @Output() public clicked = new EventEmitter<Event>();
}
