/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IAppDetails } from '../../shared/misc/interfaces/app-details.interface';
import { Inject, Injectable } from '@angular/core';
import { JbdCoreUserRoleService } from '../user-role/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreRedirectService {
  public static setRedirectUrl(url: string): void {
    window.localStorage.setItem('login_redirect_url', url);
  }

  public static clearRedirectUrl(): void {
    window.localStorage.removeItem('login_redirect_url');
  }

  constructor(
    @Inject('environment') private environment: { appDetails: IAppDetails },
    private userRoleService: JbdCoreUserRoleService
  ) {}

  public getRedirectUrl(): string {
    const isPartnerAdmin = this.userRoleService.hasRole('ROLE_PARTNER_ADMIN');
    let fallbackRoute: string;

    if (this.environment.appDetails.type === 'partner') {
      fallbackRoute = isPartnerAdmin
        ? this.environment.appDetails.urls.adminDashboard!
        : this.environment.appDetails.urls.inviteDashboard!;
    } else {
      fallbackRoute = this.environment.appDetails.urls.dashboard!;
    }

    return window.localStorage.getItem('login_redirect_url') ?? fallbackRoute;
  }
}
