import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreUserRoleService {
  public getRoles(): string[] {
    const userString = localStorage.getItem('user') ?? '';
    const user = JSON.parse(decodeURIComponent(escape(atob(userString))));

    return user?.roles ?? [];
  }

  public hasRole(role: string): boolean {
    return this.getRoles().includes(role);
  }

  public hasRoles(roles: string[]): boolean {
    return roles.some((role: string) => this.hasRole(role));
  }
}
