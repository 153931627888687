<ng-container *transloco="let t">
  <div class="fixed-width-container-sm">
    <jbd-ui-layout-header
      [title]="t('CORE.ACCOUNT.REGISTER.TITLE')"
    ></jbd-ui-layout-header>

    <div
      *ngIf="successfulSubmitted"
      @fade-toggle
    >
      <jbd-ui-layout-notification-block
        [state]="'success'"
        [title]="t('ACCOUNT.REGISTER.' + (userIsInvited ? 'SUCCESS_INVITE' : 'SUCCESS_REGISTER') + '.TITLE')"
        [message]="t('ACCOUNT.REGISTER.' + (userIsInvited ? 'SUCCESS_INVITE' : 'SUCCESS_REGISTER') + '.MESSAGE')"
      >
        <jbd-ui-button-secondary
          [label]="t('ACCOUNT.REGISTER.' + (userIsInvited ? 'SUCCESS_INVITE' : 'SUCCESS_REGISTER') + '.ACTION.PRIMARY')"
          [autoStretch]="true"
          (clicked)="router.navigate(['benutzerkonto/einloggen'])"
        ></jbd-ui-button-secondary>
      </jbd-ui-layout-notification-block>
    </div>

    <div
      *ngIf="!successfulSubmitted"
      @fade-toggle
      class="jbd-card"
    >
      <div fxLayout="column" fxLayout.gt-xs="row">
        <p class="mr-8">
          {{ t('ACCOUNT.REGISTER.ALREADY_USER_ACCOUNT.MESSAGE') }}
        </p>
        <a routerLink="/benutzerkonto/einloggen">
          <span>{{t('CORE.ACCOUNT.REGISTER.ALREADY_USER_ACCOUNT.LINK')}}</span>
          <mat-icon svgIcon="fas_chevron-right" class="ml-4"></mat-icon>
        </a>
      </div>

      <form
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
        [ngClass]="{ 'is-disabled': registerForm.disabled }"
        jbdUiFocusFirstInvalidField
        fxLayout="column"
        fxLayoutAlign="start"
        novalidate
      >
        <div
          class="pt-32"
          #alertElement
        >
          <jbd-ui-alert
            *ngIf="alert.isVisible"
            @fade-toggle
            [type]="alert.type"
            [message]="alert.message"
            [isDismissible]="alert.isDismissible"
            (dismissed)="alert.isVisible = false"
            class="block mb-32"
          ></jbd-ui-alert>
        </div>

        <div class="mb-32">
          <jbd-ui-form-input
            [type]="'email'"
            [label]="t('UI.SHARED.FORM.LABELS.EMAIL')"
            [hint]="userIsInvited ? t('ACCOUNT.REGISTER.FORM.FIELD.EMAIL.HINT') : '' "
            [errorTranslationPrefix]="'UI.SHARED.FORM.ERROR.INFORMALLY.'"
            [formGroup]="registerForm"
            [patternName]="'email'"
            [name]="'email'"
          ></jbd-ui-form-input>
        </div>

        <div
          fxLayout="column"
          [ngClass]="userIsInvited ? 'mb-48' : 'mb-32'">
          <jbd-ui-form-input
            [type]="passwordFieldType"
            [suffixButton]="{
            icon: passwordFieldType === 'password' ? 'fas_eye' : 'fas_eye-slash'
          }"
            (clickedSuffixButton)="passwordFieldType = passwordFieldType === 'password'
          ? 'text'
          : 'password'"
            [label]="t('UI.SHARED.FORM.LABELS.PASSWORD')"
            [hint]="t('UI.SHARED.FORM.HINTS.PASSWORD')"
            [errorTranslationPrefix]="'UI.SHARED.FORM.ERROR.INFORMALLY.'"
            [formGroup]="registerForm"
            [name]="'plainPassword'"
          ></jbd-ui-form-input>
        </div>

        <div
          *ngIf="!userIsInvited"
          fxLayout="column"
          class="mb-48">
          <jbd-ui-form-checkbox
            [formGroup]="registerForm"
            [name]="'tosAccepted'"
            [errorTranslationPrefix]="'ACCOUNT.REGISTER.FORM.FIELD.TOS_ACCEPTED.ERROR.'"
            [inlineLabel]="t('CORE.ACCOUNT.REGISTER.FORM.FIELD.TOS_ACCEPTED.INLINE_LABEL', {gtc: (legalLinks$ | async)!.gtc, privacyAgreement: (legalLinks$ | async)!.privacyAgreement})"
          ></jbd-ui-form-checkbox>
        </div>

        <jbd-ui-button-primary
          [label]="t('CORE.ACCOUNT.REGISTER.FORM.ACTION.PRIMARY')"
          [autoStretch]="true"
          [isLoading]="registerForm.disabled"
          [disabled]="registerForm.disabled"
          [type]="'submit'"
        ></jbd-ui-button-primary>
      </form>
    </div>

    <jbd-ui-account-legal-links
      [legalLinks]="(legalLinks$ | async)!"
    ></jbd-ui-account-legal-links>
  </div>
</ng-container>
