import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jbd-ui-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  standalone: true,
  imports: [FlexLayoutModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiHintComponent {
  @Input() public colorClass = 'text-monochrome-700';
  @Input() public iconColorClass = this.colorClass;
  @Input() public icon = 'far_info-circle';
  @Input() public hint!: string;
  @Input() public e2eId!: string;
}
