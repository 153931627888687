import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { IJbdUiDropdownItem } from '../../shared/misc/interfaces/dropdown-item.interface';
import { ExtendedThemePalette } from '../../shared/misc/types/extended-theme-palette';
import { JbdUiSizeType } from '../../shared/misc/types/size';
import { JbdUiIconButtonComponent } from '../button/icon/icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jbd-ui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    JbdUiIconButtonComponent,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiDropdownComponent {
  public menuIsOpen = false;

  @Input() public items!: IJbdUiDropdownItem[];
  @Input() public menuClassList?: string[];
  @Input() public e2eId = 'dropdown';
  @Input() public triggerIcon = 'fas_ellipsis-v';
  @Input() public triggerSize: JbdUiSizeType = 'sm';
  @Input() public triggerColor: ExtendedThemePalette = 'grey-600';
  @Input() public customTriggerTemplate?: TemplateRef<any>;
  @Input() public wrapLabels = false;

  @Output() public actionClicked = new EventEmitter();
}
