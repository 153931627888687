import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Directive({
  selector: '[jbdUiFocusFirstField]',
  standalone: true,
})
export class JbdUiFormFocusFirstFieldDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const input = this.elementRef.nativeElement.querySelector(
        '.form-control'
      ) as HTMLElement;

      if (input) {
        setTimeout(() => {
          input.scrollIntoView(JBD_CORE_SCROLL_CONFIG_TO_END);
          input.focus();
        });
      }
    });
  }
}
