import * as Sentry from '@sentry/angular';
import { ISentry } from './sentry.interface';

export function enableSentry({
  dsn,
  release,
  environment,
  tracesSampleRate,
  serverUrl,
}: ISentry) {
  Sentry.init({
    dsn,
    release,
    environment,
    tracesSampleRate,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
    ],
    tracePropagationTargets: ['localhost', serverUrl],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
