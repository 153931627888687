import { TranslocoConfig } from '@jsverse/transloco/lib/transloco.config';

export const getTranslocoConfig = (environment: {
  production: boolean;
}): Partial<TranslocoConfig> => ({
  availableLangs: ['de'],
  defaultLang: 'de',
  fallbackLang: 'de',
  prodMode: environment.production,
  flatten: {
    aot: environment.production,
  },
});
