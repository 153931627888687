<ng-container *transloco="let t">
  <div
    class="bg-monochrome-100 border-color-100 border-radius-sm p-12"
    fxLayout="column"
    fxLayout.gt-xs="row wrap"
    fxLayoutAlign="start"
    fxLayoutGap="4px"
  >
    <label
      class="mat-label"
      [attr.data-e2e]="'one-time-link-widget-label'"
      fxFlexOrder="1"
      fxFlex="1 1 100%"
      fxFlex.gt-xs="1 0 auto"
    >{{t('UI.ACCOUNT.ONE_TIME.LABELS.' + oneTimeLabelPath)}}
    </label>

    <a
      fxFlexOrder="3"
      fxFlex="1 1 100%"
      fxFlexAlign="end"
      fxFlex.gt-xs="0 0 auto"
      fxFlexOrder.gt-xs="2"
      fxFlexAlign.gt-xs="auto"
      [attr.data-e2e]="'one-time-link-widget-help-dialog-trigger'"
      (click)="openHelpDialog()"
      href="javascript:void(0)"
    >
      <mat-icon
        svgIcon="far_question-circle"
        class="font-size-sm mr-4"
      ></mat-icon>
      <span>{{ t('UI.ACCOUNT.ONE_TIME.LINK')}}</span>
    </a>

    <jbd-ui-button-tertiary
      fxFlexOrder="2"
      fxFlex="1 1 100%"
      fxFlexOrder.gt-xs="3"
      class="my-8 mb-sm-0"
      (clicked)="openLinkDialog()"
      [e2eId]="'one-time-link-widget-link-dialog-trigger'"
      [label]="t('UI.ACCOUNT.ONE_TIME.BUTTON')"
      [autoStretch]="true"
    ></jbd-ui-button-tertiary>
  </div>
</ng-container>
