import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { JbdUiButtonIconPosition } from '../../../../shared/misc/types/icon-position';
import { JbdUiButtonPrimaryComponent } from '../../../../elements/button/primary/primary.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { JbdUiIconButtonComponent } from '../../../../elements/button/icon/icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jbd-ui-layout-header-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    JbdUiButtonPrimaryComponent,
    JbdUiIconButtonComponent,
    MatIconModule,
    MatMenuModule,
    NgIf,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLayoutHeaderActionComponent {
  public menuIsOpen = false;

  @Input() public buttonIcon?: string;
  @Input() public buttonIconPosition!: JbdUiButtonIconPosition;
  @Input() public buttonLabel!: string;
  @Input() public e2eId = 'layout-header-action';

  @Output() clicked = new EventEmitter<Event>();
}
