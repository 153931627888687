import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JbdCoreHttpInterceptor } from '../../utils/http/http.interceptor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProviders = (environment: any) => [
  {
    provide: 'environment',
    useValue: environment,
  },
  ...((environment.name !== 'local' && [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ]) ||
    []),
  {
    provide: HTTP_INTERCEPTORS,
    useFactory: () => {
      return new JbdCoreHttpInterceptor(environment);
    },
    multi: true,
  },
];
