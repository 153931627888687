import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IJbdBookingOverviewCalendarEvent } from '../../misc/interfaces/calendar-event.interface';
import { IJbdLocationBooking } from '../../misc/interfaces/booking.interface';
import { JbdCalendarEventIconEnum } from '../../misc/enums/calendar-event-icon.enum';

@Component({
  selector: 'jbd-app-shared-booking-event',
  templateUrl: './booking-event.component.html',
  styleUrls: ['./booking-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdSharedBookingEventComponent implements OnInit {
  @Input() public showPeriod = true;
  @Input() public event!: IJbdBookingOverviewCalendarEvent;

  public eventIcon!: string;

  public ngOnInit(): void {
    this.eventIcon = this.setEventIcon();
  }

  private setEventIcon(): string {
    const state =
      (this.event.extendedProps.data as IJbdLocationBooking)?.state ??
      this.event.extendedProps.type;

    return JbdCalendarEventIconEnum[
      state.toUpperCase() as keyof typeof JbdCalendarEventIconEnum
    ];
  }
}
