<ng-container *transloco="let t">
  <div
    fxLayoutAlign="start center"
  >
    <div class="event-icon mr-8">
      <mat-icon svgIcon="{{eventIcon}}"></mat-icon>
    </div>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <span class="font-weight-semibold font-size-sm text-nowrap">{{ t(event.title) }}</span>
      <span
        *ngIf="showPeriod"
        class="font-weight-semibold font-size-xs text-nowrap period"
      >({{event.start | date: 'dd.MM.yyyy'}}&nbsp;–&nbsp;{{event.end | date: 'dd.MM.yyyy'}})</span>
    </div>
  </div>
</ng-container>
