import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Directive()
export abstract class JbdUiFormAbstractFieldComponent implements OnInit {
  public field!: AbstractControl | null;
  public isRequired!: boolean;

  @Input() public errorTranslationPrefix = 'UI.SHARED.FORM.ERROR.FORMALLY.';
  @Input() public formGroup!: UntypedFormGroup;
  @Input() public hideAsterisk = true;
  @Input() public hint = '';
  @Input() public label?: string;
  @Input() public name!: string;
  @Input() public patternName?: string;
  @Input() public showOptionalHint = false;

  public ngOnInit(): void {
    this.field = this.formGroup.get(this.name);
  }
}
