import localeDE from '@angular/common/locales/de';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

registerLocaleData(localeDE);

const SHARED_MODULES = [
  CommonModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslocoModule,
];

const SHARED_PROVIDERS = [
  {
    provide: LOCALE_ID,
    useValue: 'de-DE',
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'EUR',
  },
];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES],
  providers: [...SHARED_PROVIDERS],
})
export class JbdCoreModule {}
