<ng-container *transloco="let t">
  <mat-nav-list class="p-0 pr-16"
                [attr.data-e2e]="'partner-menu'">
    <ng-container *ngIf="locations && locations.length > 0">
      <h6 class="mx-16">{{ t('NAVIGATION.LIST.LOCATIONS.HEADER') }}</h6>
      <mat-accordion [displayMode]="'flat'">
        <ng-container *ngFor="let location of locations">
          <mat-expansion-panel
            [expanded]="bookingOverview.isActive || payoutOverview.isActive"
            [@.disabled]="animationsDisabled"
            class="mat-elevation-z0"
            [attr.data-e2e]="'partner-menu-location-panel-' + location.id"
          >
            <mat-expansion-panel-header class="pr-16">
              <mat-panel-title
                class="font-weight-bold font-size-xs block text-truncate"
              >{{ location.name }}</mat-panel-title>
            </mat-expansion-panel-header>
            <a
              mat-list-item
              #bookingOverview="routerLinkActive"
              [routerLink]="['/mein-unternehmen/standort', location.id, 'buchungsuebersicht']"
              (click)="layoutService.closeSidenav()"
              routerLinkActive="is-active"
            >
              <span
                class="text-monochrome-900 font-size-xs font-weight-semibold"
                matListItemTitle
              >{{ t('NAVIGATION.LIST.LOCATIONS.ITEMS.BOOKING_OVERVIEW') }}</span>
            </a>
            <a
              mat-list-item
              #payoutOverview="routerLinkActive"
              [routerLink]="['/mein-unternehmen/standort', location.id, 'auszahlungsuebersicht']"
              (click)="layoutService.closeSidenav()"
              routerLinkActive="is-active"
            >
              <span
                class="text-monochrome-900 font-size-xs font-weight-semibold"
                matListItemTitle
              >{{ t('NAVIGATION.LIST.LOCATIONS.ITEMS.PAYOUT_OVERVIEW') }}</span>
            </a>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-container>

    <h6 class="mx-16 mb-8"
        [ngClass]="{'mt-24': locations && locations.length > 0}"
    >{{ t('NAVIGATION.LIST.ADMINISTRATION.HEADER') }}</h6>

    <a
      mat-list-item
      [routerLink]="['/mein-unternehmen']"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="layoutService.closeSidenav()"
      routerLinkActive="is-active"
    >
      <span
        class="text-monochrome-900 font-size-xs font-weight-bold"
        matListItemTitle
      >{{ t('NAVIGATION.LIST.ADMINISTRATION.ITEMS.COMPANY') }}</span>
    </a>
    <a
      mat-list-item
      [routerLink]="['/benutzerverwaltung']"
      (click)="layoutService.closeSidenav()"
      routerLinkActive="is-active"
    >
      <span
        class="text-monochrome-900 font-size-xs font-weight-bold"
        matListItemTitle
      >{{ t('NAVIGATION.LIST.ADMINISTRATION.ITEMS.USER_MANAGEMENT') }}</span>
    </a>
  </mat-nav-list>
</ng-container>
