<ng-container *transloco="let t">
  <div class="fixed-width-container-sm">
    <jbd-ui-layout-header
      [title]="t('CORE.ACCOUNT.PASSWORD_FORGOTTEN.TITLE')"
    ></jbd-ui-layout-header>

    <div
      *ngIf="successfulSubmitted"
      @fade-toggle
    >
      <jbd-ui-layout-notification-block
        [state]="'success'"
        [title]="t('ACCOUNT.PASSWORD_FORGOTTEN.SUCCESS.TITLE')"
        [message]="t('ACCOUNT.PASSWORD_FORGOTTEN.SUCCESS.MESSAGE',  {emailAddress: passwordForgottenForm.get('email')!.value})"
      >
        <jbd-ui-button-secondary
          [label]="t('ACCOUNT.PASSWORD_FORGOTTEN.SUCCESS.ACTION.PRIMARY')"
          [autoStretch]="true"
          (clicked)="router.navigate(['benutzerkonto/einloggen'])"
        ></jbd-ui-button-secondary>
      </jbd-ui-layout-notification-block>
    </div>

    <div
      *ngIf="!successfulSubmitted"
      @fade-toggle
      class="jbd-card"
    >
      <p class="font-size-sm mb-0">{{t('ACCOUNT.PASSWORD_FORGOTTEN.COMFORT_USER')}}</p>

      <form
        [formGroup]="passwordForgottenForm"
        (ngSubmit)="onSubmit()"
        [ngClass]="{ 'is-disabled': passwordForgottenForm.disabled }"
        jbdUiFocusFirstInvalidField
        jbdUiFocusFirstField
        fxLayout="column"
        fxLayoutAlign="start"
        novalidate
      >
        <div
          class="pt-32"
          #alertElement
        >
          <jbd-ui-alert
            *ngIf="alert.isVisible"
            @fade-toggle
            [type]="alert.type"
            [message]="alert.message"
            [isDismissible]="alert.isDismissible!"
            (dismissed)="alert.isVisible = false"
            class="block mb-32"
          ></jbd-ui-alert>
        </div>

        <div class="mb-32">
          <jbd-ui-form-input
            [type]="'email'"
            [label]="t('UI.SHARED.FORM.LABELS.EMAIL')"
            [hideAsterisk]="true"
            [errorTranslationPrefix]="'UI.SHARED.FORM.ERROR.INFORMALLY.'"
            [formGroup]="passwordForgottenForm"
            [patternName]="'email'"
            [name]="'email'"
          ></jbd-ui-form-input>
        </div>

        <jbd-ui-button-primary
          [label]="t('CORE.ACCOUNT.PASSWORD_FORGOTTEN.FORM.ACTION.PRIMARY')"
          [autoStretch]="true"
          [isLoading]="passwordForgottenForm.disabled"
          [disabled]="passwordForgottenForm.disabled"
          [type]="'submit'"
        ></jbd-ui-button-primary>
      </form>

      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="center center"
        class="mt-8"
      >
        <p class="mb-0 mr-8">
          {{ t('CORE.ACCOUNT.PASSWORD_FORGOTTEN.FOUND_PASSWORD.MESSAGE') }}
        </p>
        <a routerLink="/benutzerkonto/einloggen">
          <span>{{ t('CORE.ACCOUNT.REGISTER.ALREADY_USER_ACCOUNT.LINK') }}</span>
          <mat-icon
            svgIcon="fas_chevron-right"
            class="ml-4"
          ></mat-icon>
        </a>
      </div>

      <hr />

      <jbd-ui-account-one-time-link-wrapper></jbd-ui-account-one-time-link-wrapper>
    </div>

    <jbd-ui-account-legal-links
      [legalLinks]="(legalLinks$ | async)!"
    ></jbd-ui-account-legal-links>
  </div>
</ng-container>
