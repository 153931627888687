import { Inject, Injectable } from '@angular/core';
import { IAppDetails } from '../../shared/misc/interfaces/app-details.interface';
import { IDecodedToken } from '../../shared/misc/interfaces/decoded-token.interface';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreAuthService {
  public static getToken(): string {
    return (
      Object.fromEntries(document.cookie.split('; ').map((c) => c.split('=')))
        ?.jwt_hp ?? ''
    );
  }

  public static decodeToken(token: string): IDecodedToken {
    const [header, payload] = token.split('.');

    return {
      raw: token,
      header: JSON.parse(window.atob(header)),
      payload: JSON.parse(window.atob(payload)),
    };
  }

  constructor(
    @Inject('environment') private environment: { appDetails: IAppDetails }
  ) {}

  public hasValidToken(): boolean {
    const token = JbdCoreAuthService.getToken();

    if (!token) {
      return false;
    }

    return (
      (JbdCoreAuthService.decodeToken(token)?.payload?.exp ?? -1) * 1000 >=
      Date.now()
    );
  }

  public clearToken() {
    const cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split('.');
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path=';
        const p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  }
}
