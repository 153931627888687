<ng-container *transloco="let t">
  <aside
    *ngIf="!isDismissed"
    @fade-toggle
    class="alert is-{{ type }}"
    [class.is-dismissible]="isDismissible"
    [attr.data-e2e]="e2eId"
    role="alert"
  >
    <jbd-ui-icon-button
      *ngIf="isDismissible"
      [color]="'grey-600'"
      [ariaLabel]="t('CORE.SHARED.CLOSE')"
      [icon]="'far_times'"
      [size]="'sm'"
      [type]="'button'"
      [attr.data-e2e]="e2eId + '-dismiss-button'"
      (clicked)="isDismissed = true; dismissed.emit()"
    ></jbd-ui-icon-button>
    <div
      class="alert-inner"
      fxLayout="row"
      fxLayoutGap="12px">
      <mat-icon
        [svgIcon]="icon" class="font-size-base"
        [attr.data-e2e]="e2eId + '-icon'"
      ></mat-icon>
      <div>
        <ng-container *ngTemplateOutlet="customContent ? customTemplate : defaultTemplate"></ng-container>

        <ng-template #defaultTemplate>
          <p
            class="font-size-sm font-weight-semibold m-0"
            [attr.data-e2e]="e2eId + '-message'"
            [innerHTML]="message | handleHrefs"
          ></p>
        </ng-template>

        <ng-template #customTemplate>

          <div class="font-size-sm font-weight-semibold m-0">
            <ng-content></ng-content>
          </div>
        </ng-template>
      </div>
    </div>
  </aside>
</ng-container>
