import { Directive, ElementRef, HostListener } from '@angular/core';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Directive({
  selector: '[jbdUiFocusFirstInvalidField]',
  standalone: true,
})
export class JbdUiFormFocusFirstInvalidFieldDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('submit', [])
  onSubmit() {
    setTimeout(() => {
      const errorInput = this.elementRef.nativeElement.querySelector(
        '.form-control.ng-invalid.ng-touched'
      ) as HTMLElement;

      if (errorInput) {
        setTimeout(() => {
          errorInput.scrollIntoView(JBD_CORE_SCROLL_CONFIG_TO_END);
          errorInput.focus();
        });
      }
    });
  }
}
