<ng-container *transloco="let t">
  <section
    class="jbd-preview-card border border-radius-sm bg-monochrome-white"
    [fxLayout]="stackedOnMobile ? 'column': 'row'"
    [ngClass]="[isSelected ? 'border-color-400' : 'border-color-200']"
    fxLayout.gt-xs="row"
  >
    <aside class="jbd-preview-card-thumbnail inline-block pl-4 py-4">
      <div
        [style.background-image]="backgroundImageUrl"
        [attr.data-e2e]="e2eId + '-thumbnail'"
        [title]="t('UI.PREVIEW_CARD.THUMBNAIL.ALT')"
        class="jbd-preview-card-thumbnail-inner"
        [class.stacked-on-mobile]="stackedOnMobile"
        fxLayoutAlign="center center"
      >
        <mat-icon
          *ngIf="!imageSrc"
          [attr.data-e2e]="e2eId + '-thumbnail-fallback-icon'"
          svgIcon="far_image"
        ></mat-icon>
      </div>
    </aside>

    <main
      class="jbd-preview-card-content px-8 pt-8 pb-4 pr-8"
      fxFlex="1 1 100%"
      fxLayout="column"
      fxLayoutAlign="space-between start">
      <header class="pl-4"
      >
        <h4 [attr.data-e2e]="e2eId + '-title'">{{title}}</h4>
        <p
          fxLayout="row"
          fxLayoutAlign="start"
          fxLayoutGap="4px"
          class="text-monochrome-700 mb-0"
        >
          <mat-icon
            *ngIf="subtitleIcon"
            [svgIcon]="subtitleIcon"
            [attr.data-e2e]="e2eId + '-subtitle-icon'"
            class="font-size-sm"
          ></mat-icon>
          <span
            *ngIf="subtitle"
            [attr.data-e2e]="e2eId + '-subtitle'"
            class="font-weight-semibold font-size-sm"
          >{{subtitle}}</span>
        </p>
      </header>
      <main fxFlex="1 1 100%"
            [attr.data-e2e]="e2eId + '-slot-main'">
        <ng-content select="[slot=main]"></ng-content>
      </main>
      <footer [attr.data-e2e]="e2eId + '-slot-footer'">
        <hr *ngIf="footerContent.children.length > 0" class="border-color-200 m-0">
        <div
          #footerContent
          fxLayoutAlign="space-between center"
          [class.mt-4]="footerContent.children.length > 0"
        >
          <ng-content select="[slot=footer]"></ng-content>
        </div>
      </footer>
    </main>
  </section>
</ng-container>
