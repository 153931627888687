<ng-container *transloco="let t">
  <div
    fxLayout="column"
    fxLayoutAlign="space-between"
    fxFlexFill
  >
    <div class="mb-64">
      <div class="pt-16 pb-64 text-center">
        <a (click)="redirectUser()"
           href="javascript:void(0)">
          <img
            ngSrc="/assets/core/images/logo/logo-full.svg"
            priority
            alt="{{t('UI.SHARED.TITLE')}}"
            width="150"
            height="75"
          >
        </a>
      </div>

      <div *ngIf="userProfileFilled">
        <ng-container
          *ngComponentOutlet="sidenavListComponent!"
        ></ng-container>
      </div>
    </div>

    <jbd-ui-layout-sidenav-user-actions
      *ngIf="user"
      [user]="user"
    ></jbd-ui-layout-sidenav-user-actions>
  </div>
</ng-container>
