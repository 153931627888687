<mat-sidenav-container
  [ngClass]="{
    'sidenav-is-overlay': media$.isActive(['xs', 'sm', 'md']),
    'sidenav-is-open' : sidenav.opened
  }">
  <mat-sidenav
    [mode]="mode"
    [position]="'start'"
    [disableClose]="mode === 'side'"
    [fixedInViewport]="mode === 'side'"
    [(opened)]="opened"
    #sidenav>
    <jbd-ui-layout-sidenav></jbd-ui-layout-sidenav>
  </mat-sidenav>

  <mat-sidenav-content
    fxLayout="column"
    class="p-0 p-sm-16 pt-sm-0 p-md-24 pt-md-0 p-lg-24 pl-lg-0"
  >
    <jbd-ui-layout-toolbar-main
      (toggleSidenav)="toggleSidenav()"
      fxFlex="0 0 auto"
    ></jbd-ui-layout-toolbar-main>
    <main
      class="app-content px-16 py-24 px-md-24 py-md-48 p-lg-48"
      fxFlex="1 0 auto"
    >
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
