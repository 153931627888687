<main mat-dialog-content>
  <p
    class="font-size-sm font-weight-semibold m-0"
    [attr.data-e2e]="'simple-dialog-title'"
    [innerHTML]="data.simple!.message!"
  ></p>
</main>
<footer mat-dialog-actions>
  <jbd-ui-button-primary
    [e2eId]="'simple-dialog-button'"
    [label]="data.simple!.buttonLabel!"
    [color]="data.simple?.buttonType ? data.simple?.buttonType : 'primary'"
    [autoStretch]="true"
    (clicked)="dialogRef.close('clicked-button')"
  ></jbd-ui-button-primary>
</footer>
