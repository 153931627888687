import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { IImpersonateDetails } from '@core/shared/misc/interfaces/impersonate-details.interface';
import { JbdCoreImpersonateService } from '@core/services/impersonate/impersonate.service';
import { JbdCoreSessionService } from '@core/services/session/session.service';
import { JbdCoreTitleService } from '@core/services/title/title.service';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { JbdNavigationPageComponent } from './navigation/page/page.component';
import { JbdUiLayoutService } from '@ui/compositions/layout/shared/layout/layout.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VERSION } from '../environments/version';
import { JbdCoreDocumentsService } from '@core/services/documents/documents.service';

@Component({
  selector: 'jbd-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeToggle],
})
export class JbdAppComponent implements OnDestroy, OnInit {
  private ngUnsubscribe = new Subject<void>();
  public impersonateDetails!: IImpersonateDetails;

  constructor(
    private documentsService: JbdCoreDocumentsService,
    private layoutService: JbdUiLayoutService,
    private sessionService: JbdCoreSessionService,
    private titleService: JbdCoreTitleService,
    private userEventService: JbdCoreUserEventService
  ) {
    this.documentsService.init();
    this.titleService.setRouteTitle('GENERAL.FALLBACK_ROUTE_TITLE');
    this.sessionService.initSessionCheck();
    this.renderImpersonationHint();
    this.setSidenavList();
    this.handleLoginEvent();
    this.handleLogoutEvent();
  }

  public ngOnInit(): void {
    this.setAppVersion();
  }

  private setAppVersion(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).appVersion = VERSION;
  }

  private setSidenavList(): void {
    this.layoutService.sidenavListComponentRef = JbdNavigationPageComponent;
  }

  private renderImpersonationHint() {
    this.impersonateDetails =
      JbdCoreImpersonateService.getImpersonationDetails();
  }

  private handleLoginEvent(): void {
    this.userEventService
      .onLogin()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.renderImpersonationHint();
      });
  }

  private handleLogoutEvent(): void {
    this.userEventService
      .onLogout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.sessionService.clearSessionCheck();
        this.renderImpersonationHint();
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
