import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { HashMap, TranslocoModule } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jbd-ui-form-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatIconModule,
    NgForOf,
    NgIf,
    TranslocoModule,
    UpperCasePipe,
  ],
})
export class JbdUiFormErrorComponent {
  @Input() field!: AbstractControl | null;
  @Input() name!: string;
  @Input() patternName?: string;
  @Input() errorTranslationPrefix!: string;

  public filterErrors(errors: ValidationErrors | null) {
    const result: { key: string; val: HashMap }[] = [];
    if (errors) {
      const keys = Object.keys(errors);

      if (keys) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const matches = key.match(/Value$/g);

          if (!matches) {
            result.push({
              key,
              val: errors[key],
            });
          }
        }
      }
    }

    return result;
  }
}
