import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JbdUiButtonAbstractComponent } from '../abstract/abstract.component';
import { ExtendedThemePalette } from '../../../shared/misc/types/extended-theme-palette';
import { JbdUiButtonInnerComponent } from '../button-inner/button-inner.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'jbd-ui-button-primary',
  templateUrl: './primary.component.html',
  styleUrls: ['./primary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JbdUiButtonInnerComponent, MatButtonModule],
})
export class JbdUiButtonPrimaryComponent extends JbdUiButtonAbstractComponent {
  @Input() color: ExtendedThemePalette = 'primary';
  @Input() e2eId = 'button-primary';
}
