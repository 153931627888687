export const environment = {
  name: 'qs',
  production: true,
  sentryId:
    'https://dcf6e4ad34f5469c8223587e5880d8e5@o390785.ingest.sentry.io/6039267',
  sentryTracesSampleRate: 0.05,
  serverUrl: 'https://qs-backend-booking2.jobodo.de',
  appDetails: {
    type: 'partner',
    urls: {
      login: '/benutzerkonto/einloggen',
      onboarding: '/onboarding',
      adminDashboard: '/mein-unternehmen',
      inviteDashboard: '/mein-unternehmen/standort',
      passwordReset: '/benutzer/sicherheit',
      customerPlatform: 'https://qs-customer-booking2.jobodo.de',
    },
  },
};
