import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILegalLinks } from './legal-links.interface';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'jbd-ui-account-legal-links',
  templateUrl: './legal-links.component.html',
  styleUrls: ['./legal-links.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslocoModule,
    MatRippleModule,
    MatDividerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiAccountLegalLinksComponent {
  @Input() public legalLinks!: ILegalLinks;
}
