<ng-container *transloco="let t; read: 'UI.SHARED.LEGAL'">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.gt-xs="row"
    fxLayoutGap.gt-xs="12px"
    fxLayoutGap="8px"
    [attr.data-e2e]="'legal-links'"
    class="py-8"
  >
    <a
      [href]="legalLinks.privacyAgreement"
      target="_blank"
      class="px-4 inline-block border-radius-sm"
      mat-ripple
    >{{t('PRIVACY_AGREEMENT')}}</a>
    <mat-divider
      fxHide.xs
      vertical
    ></mat-divider>
    <a
      [href]="legalLinks.gtc"
      target="_blank"
      class="px-4 inline-block border-radius-sm"
      mat-ripple
    >{{t('GTC')}}</a>
    <mat-divider fxHide.xs vertical></mat-divider>
    <a
      [href]="legalLinks.siteNotice"
      target="_blank"
      class="px-4 inline-block border-radius-sm"
      mat-ripple
    >{{t('SITE_NOTICE')}}</a>
  </div>
</ng-container>
