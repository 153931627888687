<ng-container *transloco="let t">
  <mat-toolbar
    fxHide.gt-md
    fxShow
    class="pl-4"
  >
    <jbd-ui-icon-button
      [icon]="'fas_bars'"
      [color]="'grey-500'"
      [attr.aria-label]="t('UI.LAYOUT.TOOLBAR.BUTTON.ARIA_LABEL')"
      [attr.data-e2e]="'toolbar-sidenav-toggle'"
      (clicked)="emitSidenavToggle()"
    ></jbd-ui-icon-button>
  </mat-toolbar>
</ng-container>
