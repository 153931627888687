import { HttpHeaders } from '@angular/common/http';

export function appendFormData(params: unknown, fd: FormData): FormData {
  for (const [key, value] of Object.entries(
    params as [key: string, value: string | Blob]
  )) {
    if (Array.isArray(value)) {
      value.map((item) => fd.append(`${key}[]`, item));
    } else {
      fd.append(key === 'file' ? `${key}[${key}]` : key, value);
    }
  }

  return fd;
}

export function setFormDataHeaders(): HttpHeaders {
  const headers = new HttpHeaders();
  headers.set('Accept', 'multipart/form-data');
  headers.set('Content-Type', 'multipart/form-data');

  return headers;
}

export function replacePathSegmentWithId(
  path: string,
  key: string,
  id: string | undefined
): string {
  if (!(path.includes(`:${key}`) && id)) {
    return path;
  }

  return path.replace(`:${key}`, id);
}
