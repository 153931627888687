import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { JbdUiLayoutService } from '../shared/layout/layout.service';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule, MediaObserver } from '@ngbracket/ngx-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JbdUiLayoutSidenavComponent } from '../sidenav/sidenav.component';
import { JbdUiLayoutToolbarMainComponent } from '../toolbar/main/main.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'jbd-ui-layout-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    JbdUiLayoutSidenavComponent,
    JbdUiLayoutToolbarMainComponent,
    MatSidenavModule,
    RouterOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLayoutPageComponent implements AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  public mode!: 'over' | 'side';
  public opened = false;

  @ViewChild('sidenav', { static: false }) public sidenav!: MatSidenav;

  constructor(
    private cdRef: ChangeDetectorRef,
    private layoutService: JbdUiLayoutService,
    public media$: MediaObserver
  ) {
    this.enableBreakpointWatcher();
  }

  public ngAfterViewInit(): void {
    this.layoutService.sidenav = this.sidenav;
  }

  private enableBreakpointWatcher(): void {
    this.media$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.setMode());
  }

  private setMode(): void {
    const m = this.media$;
    this.mode = m.isActive(['xs', 'sm', 'md']) ? 'over' : 'side';
    this.opened = m.isActive(['lg', 'xl']);
    this.layoutService.sidenavViewMode = this.mode;
    this.cdRef.markForCheck();
  }

  public toggleSidenav(): void {
    void this.sidenav.toggle();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
