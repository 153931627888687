<div
  [attr.data-e2e]="e2eId"
  [fxLayout]="type === 'page' ? 'column' : 'row'"
  fxLayoutAlign="start center"
  [fxLayoutGap]="type === 'page' ? '16px' : '8px'"
  [ngClass]="type === 'page' ? 'py-40' : 'py-4'"
>
  <div>
    <mat-spinner
      [diameter]="diameter"
      [attr.data-e2e]="e2eId + '-spinner'"
      [color]="color"
    ></mat-spinner>
  </div>
  <p
    *ngIf="label"
    [attr.data-e2e]="e2eId + '-label'"
    class="m-0"
    [class.text-nowrap]="type === 'inline'"
    [class.text-center]="type === 'page'"
  >{{label}}</p>
</div>
