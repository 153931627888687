<div class="jbd-card"
     [ngClass]="layoutMode === 'default' ? 'py-20 px-16' : 'py-16 px-12'"
>
  <div
    [fxLayoutGap]="layoutMode === 'default' ? '16px' : '12px'"
    fxLayout="row"
  >
    <jbd-ui-state-icon
      [e2eId]="e2eId + '-state-icon'"
      [state]="state"
      [size]="layoutMode === 'default' ? 'default' : 'sm'"
    ></jbd-ui-state-icon>
    <div>
      <h4
        [ngClass]="layoutMode === 'default' ? 'mb-16' : 'mb-12'"
        [attr.data-e2e]="e2eId + '-title'"
      >{{title}}</h4>
      <p
        class="font-size-sm font-weight-semibold mb-0"
        [attr.data-e2e]="e2eId + '-message'"
        [innerHTML]="message"
      ></p>
    </div>
  </div>

  <div [attr.data-e2e]="e2eId + '-slot-content'"
       #content
       [ngClass]="{
         'mt-48': layoutMode === 'default' && content.children.length > 0,
         'mt-32': layoutMode === 'dense' && content.children.length > 0
       }"
  >
    <ng-content></ng-content>
  </div>
</div>
