import { Injectable, Type } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class JbdUiLayoutService {
  private _componentRef!: Type<any>;

  public sidenav: MatSidenav | undefined;
  public sidenavViewMode: string | undefined;

  get sidenavListComponentRef(): Type<any> {
    return this._componentRef;
  }

  set sidenavListComponentRef(component: Type<any>) {
    this._componentRef = component;
  }

  public closeSidenav(): void {
    if (this.sidenav && this.sidenavViewMode === 'over') {
      void this.sidenav.close();
    }
  }
}
