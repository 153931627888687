import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JbdCoreAuthService } from '../auth/auth.service';
import { JbdCoreUserEventService } from '../user-event/user-event.service';
import { JbdCoreUserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreSessionService implements OnDestroy {
  private checkForExpiredSessionInterval!: number;
  private interval = 1000 * 60 * 5;

  constructor(
    private router: Router,
    private userEventService: JbdCoreUserEventService,
    private userService: JbdCoreUserService
  ) {}

  public initSessionCheck(): void {
    void this.handleTokenCheck();

    this.checkForExpiredSessionInterval = window.setInterval(
      this.handleTokenCheck.bind(this),
      this.interval
    );
  }

  public clearSessionCheck(): void {
    window.clearInterval(this.checkForExpiredSessionInterval);
  }

  private async handleTokenCheck(): Promise<void> {
    if (this.userService.isLoggedIn() || !JbdCoreAuthService.getToken()) {
      return;
    }

    const loginRoute: string | undefined = await this.userService.logoutUser();

    this.userEventService.triggerLogout();
    void this.router.navigate([loginRoute]);
  }

  public ngOnDestroy(): void {
    this.clearSessionCheck();
  }
}
