import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { JbdUiAlertIconEnum } from '../../shared/misc/enums/alert-icon.enum';
import { JbdUiAlertType } from '../../shared/misc/types/alert';
import { JbdUiAlertIconType } from '../../shared/misc/types/alert-icon';
import { JbdUiIconButtonComponent } from '../button/icon/icon.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { JbdCoreModule } from '@core';
import { JbdCoreHandleHrefsPipe } from '@core/pipes/handle-hrefs/handle-hrefs.pipe';

@Component({
  selector: 'jbd-ui-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [
    JbdUiIconButtonComponent,
    FlexLayoutModule,
    TranslocoModule,
    NgIf,
    MatIconModule,
    JbdCoreModule,
    JbdCoreHandleHrefsPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdUiAlertComponent implements OnInit {
  @Input() public e2eId = 'alert';
  @Input() public customContent = false;
  @Input() public isDismissible? = false;
  @Input() public message!: string;
  @Input() public type!: JbdUiAlertType;

  public isDismissed = false;
  public icon!: JbdUiAlertIconType;

  @Output() public dismissed = new EventEmitter<undefined>();

  public ngOnInit(): void {
    this.icon = this.setInfoIcon();
  }

  private setInfoIcon(): JbdUiAlertIconType {
    return JbdUiAlertIconEnum[
      this.type.toUpperCase() as keyof typeof JbdUiAlertIconEnum
    ];
  }
}
