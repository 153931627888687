<jbd-ui-button-primary
  [e2eId]="e2eId + '-action-button'"
  [label]="buttonLabel"
  [icon]="buttonIcon"
  [iconPosition]="buttonIconPosition"
  [autoStretchOnMobile]="true"
  (clicked)="clicked.emit($event)"
  fxShow.gt-xs
  fxHide
></jbd-ui-button-primary>

<jbd-ui-icon-button
  [icon]="'fas_ellipsis-v'"
  [e2eId]="e2eId + '-action-button-menu-trigger'"
  [color]="'grey-700'"
  [matMenuTriggerFor]="menu"
  [class.is-open]="menuIsOpen"
  (menuOpened)="menuIsOpen = true"
  (menuClosed)="menuIsOpen = false"
  fxHide.gt-xs
  fxShow
></jbd-ui-icon-button>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button
      (click)="clicked.emit($event)"
      [attr.data-e2e]="'list-item-dropdown-action-edit' + e2eId"
      mat-menu-item
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
    >
      <mat-icon
        *ngIf="buttonIcon && buttonIconPosition === 'pre'"
        [svgIcon]="buttonIcon"
        class="text-monochrome-500"
      ></mat-icon>
      <span>{{buttonLabel}}</span>
      <mat-icon
        *ngIf="buttonIcon && buttonIconPosition === 'post'"
        [svgIcon]="buttonIcon"
        class="text-monochrome-500"
      ></mat-icon>
    </button>
  </ng-template>
</mat-menu>
