import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { JbdAccountModule } from './account/account.module';
import { JbdAppComponent } from './app.component';
import { JbdAppRoutingModule } from './app-routing.module';
import { JbdNavigationModule } from './navigation/navigation.module';
import { JbdSharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { getProviders } from '@core/shared/config/app-providers.config';
import { JbdUiAlertComponent } from '@ui/elements/alert/alert.component';

const IMPORTED_COMPONENTS = [JbdUiAlertComponent];

const IMPORTED_MODULES = [
  BrowserAnimationsModule,
  BrowserModule,
  HttpClientModule /*import only once, otherwise httpInterceptor will be overwritten by further instances*/,
  JbdAccountModule,
  JbdAppRoutingModule,
  JbdNavigationModule,
  JbdSharedModule,
  TranslocoRootModule,
];

const COMPONENT_DECLARATIONS = [JbdAppComponent];

@NgModule({
  declarations: [...COMPONENT_DECLARATIONS],
  imports: [...IMPORTED_MODULES, ...IMPORTED_COMPONENTS],
  providers: [...getProviders(environment)],
  bootstrap: [JbdAppComponent],
})
export class JbdAppModule {}
