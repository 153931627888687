<ng-container *transloco="let t">
  <main
    [ngClass]="{'is-loading-file': isLoadingFile}"
    mat-dialog-content
  >
    <jbd-ui-loader
      *ngIf="isLoadingFile"
      [label]="t('SHARED.COMPONENTS.DIALOG.CROP_IMAGE.LOADER')"
      class="bg-monochrome-white border-radius py-24 px-16"
    ></jbd-ui-loader>

    <div class="cropper-wrapper">
      <div class="mb-32">
        <label
          class="mat-label mb-8"
        >{{t('SHARED.COMPONENTS.DIALOG.CROP_IMAGE.FORM.FIELD.CROPPED_IMAGE.LABEL')}}</label>
        <image-cropper
          [imageBase64]="data.contentData?.item?.['previewFile']"
          [maintainAspectRatio]="true"
          (cropperReady)="isLoadingFile = false"
          (loadImageFailed)="isLoadingFile = false"
          (imageCropped)="croppedImage = $event.base64!"
          output="base64"
          format="jpeg"
          class="p-0 pt-4"
        ></image-cropper>

        <jbd-ui-hint
          [hint]="t('SHARED.COMPONENTS.DIALOG.CROP_IMAGE.FORM.FIELD.CROPPED_IMAGE.HINT')"
        ></jbd-ui-hint>
      </div>

      <jbd-ui-preview-card
        [imageSrc]="croppedImage"
        [title]="data.contentData?.locationTitle"
        [subtitle]="t('COMPANY.ADD_LOCATION.STEPS.BOOKING_PROFILE.FORM.FIELD.PREVIEW_IMAGE.CARD.SUBTITLE')"
        [subtitleIcon]="'fas_eye'"
        class="media-preview-card"
      >
        <ng-container slot="footer">
          <jbd-ui-button-tertiary
            [label]="t('CORE.SHARED.SELECT')"
            [icon]="'fas_chevron-right'"
            [size]="'sm'"
            [iconPosition]="'post'"
            class="ml-auto mt-4"
          ></jbd-ui-button-tertiary>
        </ng-container>
      </jbd-ui-preview-card>
    </div>
  </main>
  <footer mat-dialog-actions>
    <jbd-ui-button-primary
      (clicked)="data.contentData!.item.displayFile = croppedImage; dialogRef.close(data.contentData?.item)"
      [label]="t('SHARED.COMPONENTS.DIALOG.CROP_IMAGE.ACTION.PRIMARY')"
      [autoStretchOnMobile]="true"
      class="ml-sm-auto"
    ></jbd-ui-button-primary>
  </footer>
</ng-container>
