import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { JbdUiLayoutService } from '@ui/compositions/layout/shared/layout/layout.service';
import { IJbdLocationListItem } from '../../../shared/misc/interfaces/location.interface';

@Component({
  selector: 'jbd-app-navigation-simple-view',
  templateUrl: './simple-view.component.html',
  styleUrls: ['./simple-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdNavigationSimpleViewComponent {
  @Input() public locations?: IJbdLocationListItem[];

  constructor(public layoutService: JbdUiLayoutService) {}
}
