<ng-container
  *transloco="let t"
  [formGroup]="formGroup"
>
  <div
    class="form-group"
    fxLayout="column"
    [ngClass]="{'is-invalid': field?.invalid && field?.touched}"
    [attr.data-e2e]="name + '-form-group'"
  >
    <mat-label
      *ngIf="label"
      [attr.data-e2e]="name + '-label'"
      class="mb-8"
    >
      <span>{{label}}</span>
      <span
        *ngIf="isRequired && !hideAsterisk"
        [attr.data-e2e]="name + '-label-required-asterisk'"
        [title]="t('UI.SHARED.FORM.HINTS.REQUIRED')"
      >&thinsp;*</span>
      <span
        *ngIf="!isRequired && showOptionalHint"
        [attr.data-e2e]="name + '-label-optional-hint'"
      >&nbsp;({{t('UI.SHARED.FORM.HINTS.OPTIONAL')}})</span>
    </mat-label>

    <div class="mat-form-field-checkbox">
      <mat-checkbox
        [formControlName]="name"
        [ngClass]="{ 'is-required': isRequired || !isRequired && hideAsterisk }"
        [required]="isRequired"
        [checked]="field?.value"
        [attr.data-e2e]="name + '-checkbox'"
        color="primary"
      >
        <span
          [innerHTML]="inlineLabel"
          [attr.data-e2e]="name + '-checkbox-inline-label'"
          class="mat-checkbox-inline-label p-8 pl-0"
        ></span>
      </mat-checkbox>
    </div>

    <div
      class="mat-mdc-form-field-subscript-wrapper"
      [ngSwitch]="field?.invalid && field?.touched ? 'error' : 'hint'"
    >
      <div
        *ngSwitchCase="'hint'"
        [@transition-messages]="subscriptAnimationState"
      >
        <div
          class="mat-form-field-hint-wrapper mt-8"
          *ngIf="hint"
        >
          <mat-hint>
            <jbd-ui-hint
              [hint]="hint"
              [e2eId]="name + '-hint'"
            ></jbd-ui-hint>
          </mat-hint>
        </div>
      </div>

      <div
        *ngSwitchCase="'error'"
        [@transition-messages]="subscriptAnimationState"
      >
        <div class="mat-form-field-error-wrapper">
          <mat-error>
            <jbd-ui-form-error
              [errorTranslationPrefix]="errorTranslationPrefix"
              [field]="field"
              [name]="name"
              [patternName]="patternName"
            ></jbd-ui-form-error>
          </mat-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>
