import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IJbdCoreDataLoginLink } from '@core/services/data/data.interface';
import { JbdUiAccountOneTimeLinkDialogComponent } from '../dialog/dialog.component';
import { JbdUiDialogContainerComponent } from '../../../../../elements/dialog/container/container.component';
import { JbdUiDialogSimpleComponent } from '../../../../../elements/dialog/simple/simple.component';
import { JbdUiSizeType } from '../../../../../shared/misc/types/size';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { JbdUiButtonTertiaryComponent } from '../../../../../elements/button/tertiary/tertiary.component';
import { JBD_UI_CUSTOM_DIALOG_OPTIONS } from '../../../../../elements/dialog/dialog.config';

@Component({
  selector: 'jbd-ui-account-one-time-link-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    JbdUiButtonTertiaryComponent,
    MatIconModule,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiAccountOneTimeLinkWidgetComponent
  implements OnInit, OnDestroy
{
  private ngUnsubscribe = new Subject<void>();
  public oneTimeLabelPath!: string;

  @Output() public clicked = new EventEmitter<IJbdCoreDataLoginLink>();

  constructor(
    private translocoService: TranslocoService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.oneTimeLabelPath = this.route.snapshot.data.routeTitle?.includes(
      'PASSWORD_FORGOTTEN'
    )
      ? 'EXTENDED'
      : 'DEFAULT';
  }

  public openHelpDialog() {
    const size: JbdUiSizeType = 'sm';

    const dialogConfig: MatDialogConfig = {
      ...JBD_UI_CUSTOM_DIALOG_OPTIONS,
      panelClass: `dialog-is-${size}`,
      data: {
        size,
        title: this.translocoService.translate(
          'UI.ACCOUNT.ONE_TIME.DIALOG.HELP.TITLE'
        ),
        contentComponent: JbdUiDialogSimpleComponent,
        simple: {
          message: this.translocoService.translate(
            'UI.ACCOUNT.ONE_TIME.DIALOG.HELP.MESSAGE'
          ),
          buttonLabel: this.translocoService.translate('CORE.SHARED.CLOSE'),
        },
      },
    };

    this.dialog.open(JbdUiDialogContainerComponent, dialogConfig);
  }

  public openLinkDialog() {
    const size: JbdUiSizeType = 'sm';

    const dialogConfig: MatDialogConfig = {
      ...JBD_UI_CUSTOM_DIALOG_OPTIONS,
      panelClass: `dialog-is-${size}`,
      data: {
        size,
        title: this.translocoService.translate(
          'UI.ACCOUNT.ONE_TIME.DIALOG.FORM.TITLE'
        ),
        contentComponent: JbdUiAccountOneTimeLinkDialogComponent,
      },
    };

    this.dialog
      .open(JbdUiDialogContainerComponent, dialogConfig)
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dialogResult: IJbdCoreDataLoginLink) => {
        if (dialogResult) {
          this.clicked.emit(dialogResult);
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
