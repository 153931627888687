<ng-container *transloco="let t">
  <jbd-ui-alert
    *ngIf="impersonateDetails.isImpersonating"
    @fade-toggle
    [type]="'info'"
    [message]="t('CORE.ADMIN.IMPERSONATE_HINT', { impersonates: impersonateDetails.impersonates})"
    class="mt-16 mr-16 mt-sm-32 mr-md-24 mt-lg-24 mr-lg-24"
  ></jbd-ui-alert>
</ng-container>
<router-outlet></router-outlet>
