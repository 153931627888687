import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FlexLayoutModule, MediaObserver } from '@ngbracket/ngx-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'jbd-ui-preview-card',
  templateUrl: './preview-card.component.html',
  styleUrls: ['./preview-card.component.scss'],
  standalone: true,
  imports: [FlexLayoutModule, MatIconModule, NgIf, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiPreviewCardComponent implements OnChanges, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  public backgroundImageUrl!: string;

  @Input() public e2eId = 'preview-card';
  @Input() public imageSrc?: string;
  @Input() public imageSrcMobile?: string;
  @Input() public stackedOnMobile = false;
  @Input() public subtitle?: string;
  @Input() public subtitleIcon?: string;
  @Input() public title!: string;
  @Input() public isSelected = false;

  constructor(private media$: MediaObserver, private cdRef: ChangeDetectorRef) {
    this.enableBreakpointWatcher();
  }

  public ngOnChanges(): void {
    this.backgroundImageUrl = this.createBackgroundImageUrl();
  }

  private enableBreakpointWatcher(): void {
    this.media$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.backgroundImageUrl = this.createBackgroundImageUrl();
        this.cdRef.markForCheck();
      });
  }

  private createBackgroundImageUrl(): string {
    if (!this.imageSrc) {
      return '';
    }

    return `url(${
      this.media$.isActive('xs') && this.stackedOnMobile && this.imageSrcMobile
        ? this.imageSrcMobile
        : this.imageSrc
    })`;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
