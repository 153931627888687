import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import {
  faCheckCircle,
  faCircle,
  faClock,
  faExclamationCircle,
  faImage,
  faInfoCircle,
  faTimes as farTimes,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faEllipsisV,
  faEnvelope,
  faMinus,
  faPen,
  faPhone,
  faPhoneVolume,
  faTimes as fasTimes,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

export const ICONS = [
  faArrowLeft,
  faArrowRight,
  faBars,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faClock,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faImage,
  faInfoCircle,
  faMinus,
  faPen,
  faPhone,
  faPhoneVolume,
  farTimes,
  fasTimes,
  faUpload,
  faUserCircle,
];
