<ng-container *transloco="let t; read: 'ACCOUNT.ACTIVATE'">
  <div class="fixed-width-container-sm">
    <jbd-ui-layout-header
      [title]="t('ROUTE_TITLE')"
    ></jbd-ui-layout-header>

    <div class="jbd-card">
      <jbd-ui-loader
        *ngIf="!alert.isVisible"
        @fade-toggle
        [label]="t('LOADER')"
      ></jbd-ui-loader>
      <div #alertElement>
        <jbd-ui-alert
          *ngIf="alert.isVisible"
          @fade-toggle
          [type]="alert.type"
          [message]="alert.message"
          (dismissed)="alert.isVisible = false"
          class="block"
        ></jbd-ui-alert>
      </div>
    </div>
  </div>
</ng-container>
