import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IJbdUiDialog } from '../dialog.interface';
import { JbdUiDialogContainerComponent } from '../container/container.component';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { JbdUiButtonPrimaryComponent } from '../../button/primary/primary.component';

@Component({
  selector: 'jbd-ui-dialog-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss'],
  standalone: true,
  imports: [MatDialogModule, JbdUiButtonPrimaryComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiDialogSimpleComponent {
  constructor(
    public dialogRef: MatDialogRef<JbdUiDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IJbdUiDialog
  ) {}
}
