import { Component, Input } from '@angular/core';
import { ExtendedThemePalette } from '../../../shared/misc/types/extended-theme-palette';
import { JbdUiButtonIconPosition } from '../../../shared/misc/types/icon-position';
import { JbdUiSizeType } from '../../../shared/misc/types/size';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@Component({
  selector: 'jbd-ui-button-inner',
  templateUrl: './button-inner.component.html',
  styleUrls: ['./button-inner.component.scss'],
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FlexLayoutModule,
    NgIf,
  ],
  standalone: true,
})
export class JbdUiButtonInnerComponent {
  @Input() e2eId!: string;
  @Input() icon?: string;
  @Input() iconPosition!: JbdUiButtonIconPosition;
  @Input() iconColor?: ExtendedThemePalette;
  @Input() isLoading = false;
  @Input() label: string | undefined;
  @Input() link: string | undefined;
  @Input() size: JbdUiSizeType = 'default';
}
