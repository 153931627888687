<ng-container *transloco="let t">
  <main mat-dialog-content>
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      [ngClass]="{ 'is-disabled': form.disabled }"
      jbdUiFocusFirstField
      jbdUiFocusFirstInvalidField
      fxLayout="column"
      fxLayoutAlign="start"
      id="form"
      [attr.data-e2e]="'one-time-link-dialog-form'"
      novalidate
    >
      <jbd-ui-form-input
        [type]="'email'"
        [label]="t('UI.ACCOUNT.ONE_TIME.DIALOG.FORM.FIELD.EMAIL.LABEL')"
        [hint]="t('UI.ACCOUNT.ONE_TIME.DIALOG.FORM.FIELD.EMAIL.HINT')"
        [hideAsterisk]="true"
        [formGroup]="form"
        [patternName]="'username'"
        [name]="'email'">
      </jbd-ui-form-input>
    </form>
  </main>
  <footer mat-dialog-actions>
    <jbd-ui-button-primary
      [label]="t('UI.ACCOUNT.ONE_TIME.DIALOG.FORM.ACTION.PRIMARY')"
      [autoStretch]="true"
      [disabled]="form.disabled"
      [isLoading]="form.disabled"
      [form]="'form'"
      [type]="'submit'"
      [e2eId]="'login-link-button'"
    ></jbd-ui-button-primary>
  </footer>
</ng-container>
