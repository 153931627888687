import { IconsModule } from '../icons/icons.module';
import { JbdCoreModule } from '@core';
import { MaterialModule } from '../material/material.module';
import { NgModule } from '@angular/core';
import { JbdSharedCropImageDialogComponent } from './components/dialog/crop-image/crop-image.component';
import { HammerModule } from '@angular/platform-browser';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { JbdSharedBookingEventComponent } from './components/booking-event/booking-event.component';
import { JbdUiLoaderComponent } from '@ui/elements/loader/loader.component';
import { JbdUiButtonPrimaryComponent } from '@ui/elements/button/primary/primary.component';
import { JbdUiButtonTertiaryComponent } from '@ui/elements/button/tertiary/tertiary.component';
import { JbdUiPreviewCardComponent } from '@ui/elements/card/preview-card/preview-card.component';
import { JbdUiHintComponent } from '@ui/elements/hint/hint.component';
import { MatRippleModule } from '@angular/material/core';
import { NgOptimizedImage } from '@angular/common';

const IMPORTED_COMPONENTS = [
  ImageCropperComponent,
  JbdUiButtonPrimaryComponent,
  JbdUiButtonTertiaryComponent,
  JbdUiHintComponent,
  JbdUiLoaderComponent,
  JbdUiPreviewCardComponent,
];

const SHARED_COMPONENTS = [
  JbdSharedBookingEventComponent,
  JbdSharedCropImageDialogComponent,
];

const SHARED_MODULES = [IconsModule, JbdCoreModule, MaterialModule];

const INTERNAL_MODULES = [HammerModule, MatRippleModule];

@NgModule({
  imports: [
    ...SHARED_MODULES,
    ...INTERNAL_MODULES,
    ...IMPORTED_COMPONENTS,
    NgOptimizedImage,
  ],
  declarations: [...SHARED_COMPONENTS],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS],
})
export class JbdSharedModule {}
