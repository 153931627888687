import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JbdUiFormRequiredService {
  public initRequiredStatus(
    formGroup: UntypedFormGroup,
    name: string
  ): boolean {
    let isRequired = false;
    const field = formGroup.get(name);

    if (field && field.validator) {
      const validator = field.validator({} as AbstractControl);
      if (validator?.required) {
        isRequired = true;
      }
    }

    return isRequired;
  }
}
