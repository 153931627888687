<ng-container *transloco="let t">
  <ng-template [ngIf]="field && field.invalid && field.touched">
    <ng-container *ngFor="let item of filterErrors(field.errors)">
      <span
        fxLayout="row"
        fxLayoutAlign="start"
        fxLayoutGap="4px"
        class="mt-8"
        [attr.data-e2e]="name + '-error'"
      >
        <mat-icon
          [attr.data-e2e]="name + '-error-icon'"
          svgIcon="far_exclamation-circle"
          class="font-size-sm"
        ></mat-icon>
        <span
          [attr.data-e2e]="name + '-error-label'"
          [innerHTML]="t(
            errorTranslationPrefix +
            (item?.key === 'pattern' ? patternName : item?.key) | uppercase,
            item?.val
          )"
        ></span>
      </span>
    </ng-container>
  </ng-template>
</ng-container>
