<ng-container
  *ngTemplateOutlet="customTriggerTemplate
  ? customTriggerTemplate
  : defaultTriggerTemplate;
  context: { menu, menuIsOpen }"
></ng-container>

<ng-template
  let-menu="menu"
  #defaultTriggerTemplate
>
  <jbd-ui-icon-button
    [icon]="triggerIcon"
    [size]="triggerSize"
    [e2eId]="e2eId + '-trigger'"
    [color]="triggerColor"
    [matMenuTriggerFor]="menu"
    [class.is-open]="menuIsOpen"
    (menuOpened)="menuIsOpen = true"
    (menuClosed)="menuIsOpen = false"
  ></jbd-ui-icon-button>
</ng-template>

<mat-menu
  class="{{menuClassList?.join(' ')}}"
  #menu="matMenu"
>
  <ng-template matMenuContent>
    <ng-container *ngFor="let item of items; index as i ; last as isLast">
      <button
        (click)="actionClicked.emit(item.emitValue ?? undefined)"
        [attr.data-e2e]="e2eId + '-menu-item-' + i"
        mat-menu-item
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
        [class.can-wrap]="wrapLabels"
      >
        <mat-icon
          *ngIf="item.icon"
          svgIcon="{{item.icon}}"
          class="text-monochrome-500 {{item.iconClassList?.join(' ')}}"
        ></mat-icon>
        <span
          [innerHTML]="item.label"
          [class.text-truncate]="!wrapLabels"
        ></span>
      </button>
      <mat-divider *ngIf="!isLast"></mat-divider>
    </ng-container>
  </ng-template>
</mat-menu>
