import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JbdCoreDataService } from '@core/services/data/data.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import {
  PASSWORD_MIN_LENGTH,
  SITE_NOTICE,
} from '@core/shared/misc/constants/global.constants';
import { JbdUserTypeEnum } from '@core/shared/misc/enums/user-type.enum';
import { IJbdUser } from '@core/shared/misc/interfaces/user.interface';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { TranslocoService } from '@jsverse/transloco';
import { ILegalLinks } from '@ui/compositions/modules/account/legal-links/legal-links.interface';
import { IJbdAlert } from '@ui/elements/alert/alert.interface';
import { JbdUiFormInputType } from '@ui/shared/misc/types/input';
import { Observable, startWith } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IJbdDocumentLink } from '@core/shared/misc/interfaces/document-links.interface';
import { JbdCoreDocumentsService } from '@core/services/documents/documents.service';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Component({
  selector: 'jbd-app-account-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdAccountRegisterComponent implements OnInit {
  private readonly routeQueryParams!: Params;
  public alert: IJbdAlert = {
    isVisible: false,
    message: '',
    type: 'danger',
    isDismissible: true,
  };
  public legalLinks$: Observable<ILegalLinks> = this.documentsService
    .onUpdate()
    .pipe(
      map((documents: IJbdDocumentLink[]) => ({
        privacyAgreement: documents.find((d) => d.type === 'PRIVACY_AGREEMENT')!
          .url,
        gtc: documents.find((d) => d.type === 'GTC_PARTNER')!.url,
        siteNotice: SITE_NOTICE.url,
      })),
      startWith({
        privacyAgreement: '',
        gtc: '',
        siteNotice: SITE_NOTICE.url,
      } as ILegalLinks)
    );
  public passwordFieldType: JbdUiFormInputType = 'password';
  public registerForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    plainPassword: [
      '',
      [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)],
    ],
    tosAccepted: [false, [Validators.requiredTrue]],
    type: JbdUserTypeEnum.PARTNER,
  });
  public successfulSubmitted = false;
  public userIsInvited = false;
  @ViewChild('alertElement', { static: false })
  public alertElement!: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private coreDataService: JbdCoreDataService,
    private documentsService: JbdCoreDocumentsService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private userService: JbdCoreUserService,
    public router: Router
  ) {
    this.routeQueryParams = this.route.snapshot.queryParams;
  }

  public ngOnInit(): void {
    this.redirectUserIfLoggedIn();
    this.handleInvite();
  }

  private redirectUserIfLoggedIn() {
    if (this.userService.isLoggedIn()) {
      void this.router.navigate([this.userService.getUserRoute()]);
    }
  }

  private handleInvite() {
    if (
      !(
        window.location.search &&
        'hash' in this.routeQueryParams &&
        'user' in this.routeQueryParams &&
        'expires' in this.routeQueryParams &&
        'inviter' in this.routeQueryParams &&
        'confirmationToken' in this.routeQueryParams
      )
    ) {
      return;
    }

    this.userIsInvited = true;
    this.registerForm.get('email')?.disable();
    this.registerForm.get('email')?.patchValue(this.routeQueryParams.user);
    this.registerForm.get('tosAccepted')?.patchValue(true);

    this.translocoService
      .selectTranslate('ACCOUNT.REGISTER.FORM.ALERT.INFO.INVITED_USER', {
        inviter: this.routeQueryParams.inviter,
      })
      .pipe(first())
      .subscribe((message: string) => {
        this.alert = {
          isVisible: true,
          message,
          type: 'info',
          isDismissible: false,
        };
      });
  }

  public onSubmit() {
    this.registerForm.markAllAsTouched();

    if (this.registerForm.invalid) {
      return;
    }

    this.registerForm.disable();

    this.handleSubmit();
  }

  private handleSubmit(): void {
    this.getEndpoint().subscribe({
      next: () => this.handleSuccessResponse(),
      error: (error: HttpErrorResponse) => this.handleErrorResponse(error),
    });
  }

  private getEndpoint(): Observable<IJbdUser> {
    if (this.userIsInvited) {
      return this.acceptInviteRequest();
    }

    return this.coreDataService.register(this.registerForm.value);
  }

  private acceptInviteRequest(): Observable<IJbdUser> {
    return this.coreDataService.acceptInvite({
      confirmationToken: this.routeQueryParams.confirmationToken,
      username: this.registerForm.get('email')?.value,
      plainPassword: this.registerForm.get('plainPassword')?.value,
    });
  }

  private handleSuccessResponse(): void {
    this.successfulSubmitted = true;
    this.cdRef.markForCheck();
  }

  private handleErrorResponse(error: HttpErrorResponse): void {
    this.registerForm.enable();
    this.alert = {
      message: this.translocoService.translate(
        `ACCOUNT.REGISTER.FORM.ALERT.ERROR.${
          error.status === 400 ? '400' : 'GENERIC'
        }`
      ),
      isVisible: true,
      isDismissible: true,
      type: 'danger',
    };
    this.alertElement.nativeElement.scrollIntoView(
      JBD_CORE_SCROLL_CONFIG_TO_END
    );
    this.cdRef.markForCheck();
  }
}
