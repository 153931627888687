import { IImpersonateDetails } from '../../shared/misc/interfaces/impersonate-details.interface';
import { IJbdUser } from '../../shared/misc/interfaces/user.interface';
import { Injectable } from '@angular/core';
import { JbdCoreAuthService } from '../auth/auth.service';
import { JbdCoreUserEventService } from '../user-event/user-event.service';
import { JbdCoreUserService } from '../user/user.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreImpersonateService {
  public static getImpersonationDetails(): IImpersonateDetails {
    const token = JbdCoreAuthService.getToken();

    if (!token) {
      return {
        isImpersonating: false,
      };
    }

    const { payload } = JbdCoreAuthService.decodeToken(token);

    return {
      isImpersonating: !!payload.switchUser,
      impersonates: payload.switchUser ?? '',
      impersonator: payload.username,
    };
  }

  constructor(
    private userEventService: JbdCoreUserEventService,
    private userService: JbdCoreUserService
  ) {}

  public handleImpersonation(): Observable<IJbdUser> {
    return this.userService
      .loadUser()
      .pipe(tap((user: IJbdUser) => this.userEventService.triggerLogin(user)));
  }
}
