import {
  faMapMarkedAlt,
  faReceipt,
  faUmbrellaBeach,
} from '@fortawesome/pro-duotone-svg-icons';
import { faCreativeCommonsBy } from '@fortawesome/free-brands-svg-icons';
import {
  faCircle,
  faClock,
  faCropAlt,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight,
  faCheck,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFilePdf,
  faImages,
  faPen,
  faPlus,
  faSearch,
  faTrashAlt,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

export const ICONS = [
  faArrowRight,
  faCheck,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClock,
  faCreativeCommonsBy,
  faCropAlt,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFilePdf,
  faImages,
  faMapMarkedAlt,
  faPen,
  faSearch,
  faPlus,
  faQuestionCircle,
  faReceipt,
  faTrashAlt,
  faUmbrellaBeach,
  faUpload,
];
