<span
  [ngStyle]="{'opacity': isLoading ? 0 : 1}"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <mat-icon
    *ngIf="icon && iconPosition === 'pre'"
    [color]="$any(iconColor)"
    [svgIcon]="icon"
    [attr.data-e2e]="e2eId + '-button-inner-icon-' + iconPosition"
    class="mr-{{size === 'xs' ? 8 : 12}}"
  ></mat-icon>
  <span
    [attr.data-e2e]="e2eId + '-button-inner-label'"
  >{{label}}</span>
  <mat-icon
    *ngIf="icon && iconPosition === 'post'"
    [svgIcon]="icon"
    [attr.data-e2e]="e2eId + '-button-inner-icon-' + iconPosition"
    class="ml-{{size === 'xs' ? 8 : 12}}"
  ></mat-icon>
</span>
<ng-container *ngIf="isLoading">
  <mat-progress-spinner
    [attr.data-e2e]="e2eId + '-button-inner-spinner'"
    [diameter]="18"
    [mode]="'indeterminate'"
  ></mat-progress-spinner>
</ng-container>
