<p fxLayout="row"
   fxLayoutAlign="start"
   fxLayoutGap="4px"
   class="{{colorClass}} m-0"
   [attr.data-e2e]="e2eId + '-hint'">
  <mat-icon
    [attr.data-e2e]="e2eId + '-hint-icon'"
    [svgIcon]="icon"
    class="font-size-sm {{iconColorClass}}"
  ></mat-icon>
  <span
    class="font-weight-semibold font-size-xs"
    [attr.data-e2e]="e2eId + '-hint-label'"
    [innerHTML]="hint"
  ></span>
</p>
