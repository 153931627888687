import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { IJbdCoreDataLoginLink } from '@core/services/data/data.interface';
import { JbdUiDialogContainerComponent } from '../../../../../elements/dialog/container/container.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { JbdUiButtonPrimaryComponent } from '../../../../../elements/button/primary/primary.component';
import { JbdUiFormFieldInputComponent } from '../../../../form/field/input/input.component';
import { JbdUiFormFocusFirstInvalidFieldDirective } from '../../../../form/shared/directives/focus-first-invalid-field/focus-first-invalid-field.directive';
import { JbdUiFormFocusFirstFieldDirective } from '../../../../form/shared/directives/focus-first-field/focus-first-field.directive';

@Component({
  selector: 'jbd-ui-account-one-time-link-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    JbdUiFormFieldInputComponent,
    JbdUiFormFocusFirstInvalidFieldDirective,
    JbdUiButtonPrimaryComponent,
    ReactiveFormsModule,
    TranslocoModule,
    JbdUiFormFocusFirstFieldDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiAccountOneTimeLinkDialogComponent {
  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<JbdUiDialogContainerComponent>
  ) {}

  public onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    this.dialogRef.close(this.form.value as IJbdCoreDataLoginLink);
  }
}
