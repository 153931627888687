import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IJbdUiFormInputSuffixButton } from '../../../../shared/misc/interfaces/input-suffix-button.interface';
import { JbdUiFormInputType } from '../../../../shared/misc/types/input';
import { JbdUiFormRequiredService } from '../../shared/services/required/required.service';
import { JbdUiFormAbstractFieldComponent } from '../abstract/abstract-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JbdUiHintComponent } from '../../../../elements/hint/hint.component';
import { JbdUiFormErrorComponent } from '../../shared/components/error/error.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'jbd-ui-form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    JbdUiFormErrorComponent,
    JbdUiHintComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
export class JbdUiFormFieldInputComponent
  extends JbdUiFormAbstractFieldComponent
  implements OnInit
{
  @Input() public type: JbdUiFormInputType = 'text';
  @Input() public placeholder = '';
  @Input() public suffixButton?: IJbdUiFormInputSuffixButton;
  @Input() public suffixLabel?: string;
  @Input() public prefixIcon?: string;

  @Output() public clickedSuffixButton = new EventEmitter<void>();

  constructor(private formService: JbdUiFormRequiredService) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isRequired = this.formService.initRequiredStatus(
      this.formGroup,
      this.name
    );
  }
}
