<button
  [color]="$any(color)"
  [disabled]="disabled"
  (click)="clicked.emit($event)"
  [attr.type]="type"
  [attr.cdkFocusInitial]="hasInitialFocus"
  [attr.form]="form"
  [attr.data-e2e]="e2eId"
  class="is-{{ size }} {{classList?.join(' ')}}"
  mat-flat-button
>
  <ng-container
    *ngTemplateOutlet="customButtonInner
    ? customButtonInnerTemplate
    : defaultButtonInnerTemplate"
  ></ng-container>

  <ng-template #defaultButtonInnerTemplate>
    <jbd-ui-button-inner
      [icon]="icon"
      [iconPosition]="iconPosition"
      [iconColor]="iconColor"
      [isLoading]="isLoading"
      [label]="label"
      [size]="size"
      [e2eId]="e2eId"
    ></jbd-ui-button-inner>
  </ng-template>

  <ng-template #customButtonInnerTemplate>
    <ng-content></ng-content>
  </ng-template>
</button>
