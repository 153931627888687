import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class JbdCorePaginatorIntl extends MatPaginatorIntl {
  public declare firstPageLabel: string;
  public declare itemsPerPageLabel: string;
  public declare lastPageLabel: string;
  public declare nextPageLabel: string;
  public declare of: string;
  public declare previousPageLabel: string;

  constructor(private translocoService: TranslocoService) {
    super();

    this.translocoService
      .selectTranslateObject('CORE.SHARED.PAGINATION')
      .pipe(first())
      .subscribe(
        ({
          ITEMS_PER_PAGE_LABEL,
          NEXT_PAGE_LABEL,
          PREVIOUS_PAGE_LABEL,
          FIRST_PAGE_LABEL,
          LAST_PAGE_LABEL,
          OF,
        }) => {
          this.itemsPerPageLabel = ITEMS_PER_PAGE_LABEL;
          this.nextPageLabel = NEXT_PAGE_LABEL;
          this.previousPageLabel = PREVIOUS_PAGE_LABEL;
          this.firstPageLabel = FIRST_PAGE_LABEL;
          this.lastPageLabel = LAST_PAGE_LABEL;
          this.of = OF;

          this.changes.next();
        }
      );
  }

  public getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.of} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex} ${this.of} ${length}`;
  };
}
