<ng-container *transloco="let t">
  <header
    mat-dialog-title
    class="bg-monochrome-white m-0 p-0"
  >
    <div
      class="mat-dialog-title-inner "
      [class.hide-close-btn]="dialogData.hideCloseBtn"
      fxLayoutAlign="start center"
      [ngClass]="dialogData.size === 'sm' ? 'p-16' : 'px-16 py-16 py-md-24'"
    >
      <h3
        *ngIf="!dialogData.size || dialogData.size === 'default' || dialogData.size === 'lg'"
        class="m-0"
        [attr.data-e2e]="'dialog-title'"
      >{{ dialogData.title }}</h3>
      <h4
        *ngIf="dialogData.size === 'sm'"
        class="m-0"
        [attr.data-e2e]="'dialog-title'"
      >{{ dialogData.title }}</h4>

      <ng-container *ngIf="!dialogData.hideCloseBtn">
        <jbd-ui-icon-button
          [e2eId]="'dialog-close-button'"
          [color]="'grey-600'"
          [ariaLabel]="t('CORE.SHARED.CLOSE')"
          [icon]="'far_times'"
          [size]="dialogData.size"
          [matDialogClose]="undefined"
        ></jbd-ui-icon-button>
      </ng-container>
    </div>
  </header>
  <main class="bg-monochrome-100">
    <ng-template [ngIf]="dialogData.contentComponent">
      <ng-container *ngComponentOutlet="dialogData.contentComponent"></ng-container>
    </ng-template>
  </main>
</ng-container>
