import { filter } from 'rxjs/operators';
import { JbdUserEventEnum } from '../../shared/misc/enums/user-event.enum';
import { IJbdUser } from '../../shared/misc/interfaces/user.interface';
import { IJbdUserEvent } from '../../shared/misc/interfaces/user-event.interface';
import { Injectable } from '@angular/core';
import { JbdCoreUserService } from '../user/user.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreUserEventService {
  private notify = new Subject<IJbdUserEvent>();

  constructor(private userService: JbdCoreUserService) {}

  public triggerLogin(user: IJbdUser) {
    this.notify.next({
      type: JbdUserEventEnum.LOGIN,
      data: user,
    } as IJbdUserEvent);
  }

  public triggerUpdate() {
    this.userService.loadUser().subscribe((user: IJbdUser) => {
      this.notify.next({
        type: JbdUserEventEnum.UPDATED,
        data: user,
      } as IJbdUserEvent);
    });
  }

  public triggerLocalUpdate(user: IJbdUser) {
    this.userService.setUser(user);

    this.notify.next({
      type: JbdUserEventEnum.UPDATED,
      data: user,
    } as IJbdUserEvent);
  }

  public triggerLogout() {
    this.notify.next({
      type: JbdUserEventEnum.LOGOUT,
    } as IJbdUserEvent);
  }

  public onEvent(): Observable<IJbdUserEvent> {
    return this.notify.asObservable();
  }

  public onLogin(): Observable<IJbdUserEvent> {
    return this.onEvent().pipe(
      filter((e: IJbdUserEvent) => e.type === JbdUserEventEnum.LOGIN)
    );
  }

  public onUpdate(): Observable<IJbdUserEvent> {
    return this.onEvent().pipe(
      filter((e: IJbdUserEvent) => e.type === JbdUserEventEnum.UPDATED)
    );
  }

  public onLogout(): Observable<IJbdUserEvent> {
    return this.onEvent().pipe(
      filter((e: IJbdUserEvent) => e.type === JbdUserEventEnum.LOGOUT)
    );
  }
}
