import { ElementRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'handleHrefs',
  standalone: true,
})
export class JbdCoreHandleHrefsPipe implements PipeTransform, OnDestroy {
  private linksInsideMessage: HTMLAnchorElement[] = [];
  private eventHandler = (e: Event) => {
    this.handleInternalLinksAlternatively(e);
  };

  constructor(public readonly elementRef: ElementRef, private router: Router) {}

  public transform(value: string): string {
    setTimeout(() => {
      this.linksInsideMessage = Array.from(
        this.elementRef.nativeElement.getElementsByTagName('a')
      );

      if (this.linksInsideMessage.length > 0) {
        this.linksInsideMessage.forEach((anchor: Node) => {
          const { eventHandler } = this;

          anchor.addEventListener('click', eventHandler);
        });
      }
    });

    return value;
  }

  private handleInternalLinksAlternatively(e: Event): void {
    const link = e.target as HTMLAnchorElement;

    if (link.getAttribute('href')?.startsWith('http')) {
      return;
    }

    e.preventDefault();
    void this.router.navigate([link.getAttribute('href')]);
  }

  public ngOnDestroy(): void {
    if (this.linksInsideMessage.length > 0) {
      this.linksInsideMessage.forEach((link: Node) => {
        link.removeEventListener('click', this.eventHandler);
      });
    }
  }
}
