import { APP_ROUTES } from './app.routes';
import { NgModule } from '@angular/core';
import { ROUTER_OPTIONS } from '@core/shared/config/router.config';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, ROUTER_OPTIONS)],
  exports: [RouterModule],
})
export class JbdAppRoutingModule {}
