import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { JbdUiSizeType } from '../../../shared/misc/types/size';
import { JbdUiStateIconEnum } from '../../../shared/misc/enums/state-icon.enum';
import { JbdUiStateIconType } from '../../../shared/misc/types/state-icon';
import { JbdUiStateType } from '../../../shared/misc/types/state';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jbd-ui-state-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiStateIconComponent implements OnChanges {
  @Input() public size: JbdUiSizeType = 'default';
  @Input() public state!: JbdUiStateType;
  @Input() public e2eId = 'state-icon';
  @Input() public customIcon?: string;
  @Input() public classList: string[] = [];

  public icon!: JbdUiStateIconType | string;

  public ngOnChanges(): void {
    this.icon = this.customIcon || this.setStateIcon();
  }

  private setStateIcon(): JbdUiStateIconType {
    const formattedState = this.state.replace('-', '_').toUpperCase();
    return JbdUiStateIconEnum[
      formattedState as keyof typeof JbdUiStateIconEnum
    ];
  }
}
